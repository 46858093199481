import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InfoOutlined } from '@material-ui/icons';
import {
    createParagraphItems,
    Empty,
    IApiContextVersion,
    IApiModuleVersion,
    IApplicationStore,
    IWorkflowActivity,
    IWorkflowCrInEditInfo,
    IWorkflowHistoricActivity,
    ModuleDiffingToggleSwitch,
    modulesSelector,
    ModuleVersionContent,
    UrlSchemeUtils,
    WorkflowUtils,
} from '@yonder-mind/ui-core';
import { IWorkflowCrEditContent } from '../../../../../interfaces';

interface DraftViewContentProps {
    document: IApiContextVersion;
    changeRequest: IWorkflowActivity | IWorkflowHistoricActivity;
    crModuleVersion: IApiModuleVersion;
    isDiffingActive: boolean;
    toggleDiffing: () => void;
    crEditContent: IWorkflowCrEditContent | undefined;
    crInEditLockInfo: IWorkflowCrInEditInfo | undefined;
    isDraftEditable: boolean;
    isCRInImplementationPhase: boolean;
    isCRInOrAfterImplementationPhaseButNotRejected: boolean;
    isDeleteCR: boolean;
    isTitleChanged: boolean;
    isAddModuleCR: boolean;
    isEditContentCR: boolean;
    isEditTitleCR: boolean;
}

export const DraftViewContent: React.FC<DraftViewContentProps> = ({
    document,
    changeRequest,
    crModuleVersion,
    isDiffingActive,
    toggleDiffing,
    crEditContent,
    crInEditLockInfo,
    isDraftEditable,
    isCRInImplementationPhase,
    isCRInOrAfterImplementationPhaseButNotRejected,
    isDeleteCR,
    isTitleChanged,
    isAddModuleCR,
    isEditContentCR,
    isEditTitleCR,
}) => {
    const { t } = useTranslation();

    const nextModule = useSelector((state: IApplicationStore) =>
        modulesSelector.moduleByContextVersionOid(state, crModuleVersion?.moduleOid, document?.oid)
    );

    const setContent = () => {
        if (!crEditContent) {
            if (!isAddModuleCR && isDiffingActive && crModuleVersion.diffContent.length) {
                return UrlSchemeUtils.replaceUrlScheme(crModuleVersion.diffContent, window.location.origin);
            }
            return crModuleVersion.content;
        }

        if (!crEditContent.diffContent?.length || !isDiffingActive || isAddModuleCR) {
            return crEditContent.moduleVersionContent;
        }

        return UrlSchemeUtils.replaceUrlScheme(crEditContent.diffContent, window.location.origin);
    };

    const showDiffingSwitch = isCRInOrAfterImplementationPhaseButNotRejected && isEditContentCR;

    const infoMessage = () => {
        const message = WorkflowUtils.getDraftViewContentInfoMessage(changeRequest, t);
        return createParagraphItems(message).map((item, index) => (
            <p key={index}>{index === 0 ? <strong>{item}</strong> : item}</p>
        ));
    };

    const showDraftContent = isEditTitleCR
        ? isTitleChanged
            ? isCRInImplementationPhase
            : true
        : isCRInOrAfterImplementationPhaseButNotRejected && !isDeleteCR && crModuleVersion;

    return (
        <>
            {showDraftContent ? (
                <>
                    <ModuleVersionContent
                        document={document}
                        moduleVersion={{
                            ...crModuleVersion,
                            content: setContent(),
                        }}
                        links={nextModule ? nextModule.links : []}
                        crInEditLockInfo={crInEditLockInfo}
                        showLockMessage={Boolean(crInEditLockInfo) && isDraftEditable}
                        isFadedOut={isEditTitleCR}
                    />
                    {showDiffingSwitch && (
                        <ModuleDiffingToggleSwitch
                            labelText={t('workflow.changeRequest.tabs.diffing.showDiffingActualDraft')}
                            isDiffingActive={isDiffingActive}
                            toggleDiffing={toggleDiffing}
                            dataTestId="chageRequestDraftViewDiffingToggleSwitch"
                        />
                    )}
                </>
            ) : (
                <Empty
                    className="content empty"
                    variant="standard"
                    icon={<InfoOutlined color={'primary'} />}
                    text={infoMessage()}
                />
            )}
        </>
    );
};
