import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import {
    docsSelector,
    IApiModuleVersionChange,
    IApplicationStore,
    IWorkflowTaskInfo,
    modulesSelector,
    routerSelectors,
    Spinner,
    useChanges,
    userActions,
    WorkflowUtils,
} from '@yonder-mind/ui-core';
import { crActions, importJobActions, useSelectorUiWeb } from '../../../store';
import { ChangeRequestWorkflow } from '../../index';
import ChangeRequestWorkflowHeader from './ChangeRequestWorkflowHeader';

interface ChangeRequestViewProps {
    contextVersionOid: string;
    key: string;
}

const CR_LOCK_POLLING_INTERVAL = 10000;

export const ChangeRequestView: React.FC<ChangeRequestViewProps> = ({ contextVersionOid }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { changeRequest: processInstanceId } = useSelector(routerSelectors.queryParams);
    const contextVersion = useSelectorUiWeb((state) => docsSelector.getDocument(state, contextVersionOid));
    const { isLoading: isLoadingDocs } = useSelectorUiWeb((state) => state.docs);
    const { username } = useSelectorUiWeb((state) => state.user.userSettings);
    const { changeRequest, isLoadingCr } = useSelectorUiWeb((state) => {
        return {
            changeRequest: state.workflow.changeRequest.currentCR,
            isLoadingCr: state.workflow.changeRequest.isLoading,
        };
    });

    const changes = useChanges();

    const crModuleVersionOid = changeRequest?.variables.CHANGE_REQUEST_MODULE_VERSION_ID;
    const crModuleOid = changeRequest?.variables.CHANGE_REQUEST_MODULE_ID;

    const currentAndPreviousModuleVersionByModuleOid = useSelector((state: IApplicationStore) =>
        modulesSelector.currentAndPreviousModuleVersionByModuleOid(state, crModuleOid)
    );
    const currentAndPreviousModuleVersionByModuleVersionOid = useSelector((state: IApplicationStore) =>
        modulesSelector.currentAndPreviousModuleVersionByModuleVersionOid(state, crModuleVersionOid)
    );
    // TODO: These functions should be properly implemented - they get draft version and latest
    // Also CHANGE_REQUEST_EDIT_MODULE_VERSION_ID is not taken into account and should be used
    const [crModuleVersion] = crModuleOid
        ? currentAndPreviousModuleVersionByModuleOid
        : currentAndPreviousModuleVersionByModuleVersionOid;

    const change = crModuleVersion
        ? (changes.moduleChanges[crModuleVersion.oid] || []).find(
              (moduleChange) => moduleChange.changeRequestId === changeRequest.id
          ) || null
        : null;

    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [isEditing, setEditing] = useState(false);
    const [visibleChange, setVisibleChange] = useState<IApiModuleVersionChange | null>(change);

    const contextVersionToEditOid = changeRequest?.variables
        ? WorkflowUtils.getContextVersionToEditOid(changeRequest?.variables)
        : null;

    useEffect(() => {
        if (contextVersionToEditOid) {
            dispatch(importJobActions.importJobLockRequested(contextVersionToEditOid));
        }
    }, [contextVersionToEditOid]);

    useEffect(() => {
        const fetchData = () => {
            contextVersionToEditOid ? dispatch(importJobActions.importJobLockRequested(contextVersionToEditOid)) : null;
        };

        if (!isEditing) {
            const intervalId = setInterval(fetchData, CR_LOCK_POLLING_INTERVAL);
            return () => clearInterval(intervalId);
        }
        return () => {};
    }, [isEditing, contextVersionToEditOid, dispatch]);

    useEffect(() => {
        if (unsavedChanges) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = undefined;
        }
    }, [unsavedChanges]);

    useEffect(() => {
        if (username === '') {
            dispatch(userActions.userSettingsRequested());
        }
        if (processInstanceId) {
            dispatch(crActions.getChangeRequest({ changeRequestId: processInstanceId }));
        }
    }, [processInstanceId]);

    useEffect(() => {
        changeRequest &&
            dispatch(
                crActions.contextVersionImportJobHasChangesRequested(
                    WorkflowUtils.getContextVersionToEditOid(changeRequest.variables)
                )
            );
    }, [changeRequest?.processInstanceId]);

    useEffect(() => {
        setVisibleChange(change);
    }, [change]);

    const hideChangeIfBackToWaitlistSubmitted = (task: IWorkflowTaskInfo) => {
        if (task.name === 'Back to waitlist') {
            setVisibleChange(null);
        }
    };

    return (
        <div className={'layout--workflow'}>
            {isLoadingDocs || isLoadingCr ? (
                <Spinner className="container" />
            ) : contextVersion ? (
                changeRequest ? (
                    <>
                        <ChangeRequestWorkflowHeader
                            changeRequest={changeRequest}
                            contextVersion={contextVersion}
                            isEditing={isEditing}
                            hideChangeIfBackToWaitlistSubmitted={hideChangeIfBackToWaitlistSubmitted}
                        />
                        <ChangeRequestWorkflow
                            document={contextVersion}
                            changeRequest={changeRequest}
                            unSavedChanges={unsavedChanges}
                            onUnsavedChanges={setUnsavedChanges}
                            setEditing={setEditing}
                            visibleChange={visibleChange}
                        />
                    </>
                ) : (
                    <Spinner className="container" />
                )
            ) : (
                <Typography className="no-items">{t('reader.noDocument')}</Typography>
            )}
            <Prompt when={unsavedChanges} message={t('common.modals.unsavedChanges.content')} />
        </div>
    );
};
