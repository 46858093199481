import React from 'react';
import { Typography, Chip } from '@material-ui/core';
import { WorkflowTasks } from '../WorkflowTasks';
import { combineStrings, CRName, WorkflowUtils } from '@yonder-mind/ui-core';
import { useTranslation } from 'react-i18next';

interface ChangeRequestWorkflowHeaderProps {
    changeRequest: any;
    contextVersion: any;
    isEditing: boolean;
    hideChangeIfBackToWaitlistSubmitted: (task: any) => void;
}

const ChangeRequestWorkflowHeader: React.FC<ChangeRequestWorkflowHeaderProps> = ({
    changeRequest,
    contextVersion,
    isEditing,
    hideChangeIfBackToWaitlistSubmitted,
}) => {
    const { t } = useTranslation();

    const importance: string = changeRequest?.variables.SEVERITY || '';
    const translatedImportance = importance ? importance : '';

    const label: string = changeRequest?.variables.CHANGE_REQUEST_LABEL || 'Label';
    const title = `${label}${label && importance ? `: ${translatedImportance}` : ''}`;

    const getChangeRequestType = () => {
        switch (changeRequest?.activityName || changeRequest?.processDefinitionName) {
            case CRName.EDIT_CONTENT:
                return t('reader.moduleView.tools.changeRequest.actions.editContent');
            case CRName.EDIT_TITLE:
                return t('reader.moduleView.tools.changeRequest.actions.editTitle');
            case CRName.ADD_MODULE:
                return t('reader.moduleView.tools.changeRequest.actions.add');
            case CRName.DELETE_MODULE:
                return t('reader.moduleView.tools.changeRequest.actions.remove');
            default:
                return '';
        }
    };

    const summary = changeRequest?.variables.CHANGE_REQUEST_SUMMARY;

    const status = changeRequest && WorkflowUtils.getStatus(changeRequest, t);

    return (
        <div className="change-request-view-header">
            <div className="column">
                {title ? (
                    <Typography variant="h5" className="title" data-testid={`header${title}`}>
                        {title}
                    </Typography>
                ) : (
                    title
                )}
                <Typography color="inherit">{getChangeRequestType()}</Typography>
            </div>
            <div className="column">
                <Typography color="inherit">{summary}</Typography>
            </div>
            <div className="column">
                <Chip
                    data-testid="change-request-status"
                    className={combineStrings([
                        'submission-badge',
                        changeRequest?.userHasTasks ? 'user-has-tasks' : null,
                        changeRequest && WorkflowUtils.isCompleted(changeRequest) ? 'completed' : null,
                    ])}
                    label={status}
                    size="medium"
                    variant={'outlined'}
                />
            </div>
            <div className="column">
                <WorkflowTasks
                    contextVersion={contextVersion}
                    variant="change-request"
                    activity={changeRequest}
                    isDisabled={isEditing}
                    onTaskSubmitted={(task) => {
                        hideChangeIfBackToWaitlistSubmitted(task);
                    }}
                />
            </div>
        </div>
    );
};

export default ChangeRequestWorkflowHeader;
