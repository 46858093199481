import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {
    DocumentClass,
    IApiChange,
    IApiContextVersion,
    IDocumentStatus,
    INotificationDeliveryStatus,
    NotificationReceiverType,
    NotificationSettingType,
    YonderButton,
} from '@yonder-mind/ui-core';
import { ChangeInfoForm } from './ChangeInfoForm';
import { NotificationSettingsTable } from './NotificationSettingsTable';
import { getGridLayout } from './changeFormLayoutUtil';

interface ChangeFormProps {
    actions: Array<{ name: string; onClick: () => any }>;
    change: IApiChange;
    hasChanges: boolean;
    changeContextVersionOid?: string;
    updateChange: (content: IApiChange) => any;
    documentClass: DocumentClass;
    disabled?: boolean;
    contextVersions?: IApiContextVersion[];
}

const useStyles = makeStyles(() =>
    createStyles({
        mapperTitle: {
            margin: '8px',
        },
    })
);

const notificationReceiverTypes: NotificationReceiverType[] = [
    NotificationReceiverType.NONE,
    NotificationReceiverType.READ,
    NotificationReceiverType.READ_ACK,
];

export const ChangeForm: React.FC<ChangeFormProps> = ({
    actions,
    change,
    hasChanges,
    changeContextVersionOid,
    updateChange,
    documentClass,
    disabled,
    contextVersions,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [editChange, setEditChange] = React.useState<IApiChange>(change);

    useEffect(() => {
        if (change && editChange !== change) {
            setEditChange(change);
        }
    }, [change]);

    const update = (updates: Partial<IApiChange>) => {
        const newChange = {
            ...change,
            ...updates,
        };

        updateChange(newChange);
        setEditChange(newChange);
    };

    const getFormActions = () => {
        //If we only have one button it should be aligned right
        if (actions.length === 1) {
            return (
                <div className="change-form-actions">
                    {actions.map((action, index) => (
                        <YonderButton
                            variant={index === actions.length - 1 ? 'contained' : 'text'}
                            children={action.name}
                            onClick={action.onClick}
                            key={index}
                            disabled={disabled}
                        />
                    ))}
                </div>
            );
        }
        const rightActions = [...actions];
        const changeFormActionsClassName = 'change-form-actions';

        return (
            <div className={changeFormActionsClassName}>
                <div className="change-actions-right">
                    {rightActions.map((action, index) => {
                        const disabledSave = action.name === t('form.actions.save') && !hasChanges;
                        return (
                            <YonderButton
                                variant={'contained'}
                                children={action.name}
                                onClick={action.onClick}
                                key={index}
                                disabled={disabled || disabledSave}
                            />
                        );
                    })}
                </div>
            </div>
        );
    };

    const getNotificationInfo = () => {
        const { deliveryDate, status } = change;
        if (changeContextVersionOid !== null) {
            const contextVersionToEdit = contextVersions?.find(
                (contextVersion) => contextVersion.oid === changeContextVersionOid
            );
            const contextVersionToEditStatus = contextVersionToEdit && contextVersionToEdit.status;
            if (
                deliveryDate === '' &&
                status === '' &&
                contextVersionToEditStatus &&
                contextVersionToEditStatus === IDocumentStatus.DRAFT
            ) {
                return t('workflow.tools.change.notificationsOnRelease');
            }
            if (status === INotificationDeliveryStatus.ERROR || status === INotificationDeliveryStatus.FAILED) {
                return t('workflow.tools.change.notificationsError');
            }
            if (status !== '' && status !== INotificationDeliveryStatus.SUCCEEDED) {
                const delivery = moment(deliveryDate);
                const now = moment();
                // FIXME this should be adjusted/fixed in the backend but for worries of side-effects it was decided to do it superficially
                //  until further investigation.
                //  Reasoning: if the delivery is pending and the delivery date is in the past the sane delivery date should be asap hence we use now
                const correctedScheduledDeliveryDate = delivery.isBefore(now) ? now : delivery;
                return t('workflow.tools.change.notificationsScheduled', {
                    date: correctedScheduledDeliveryDate.format('DD.MM.YYYY'),
                });
            }
            if (status === INotificationDeliveryStatus.SUCCEEDED) {
                return t('workflow.tools.change.notificationsSent', {
                    date: moment(deliveryDate).format('DD.MM.YYYY'),
                });
            }
        }
        return '';
    };

    const notificationInfo = getNotificationInfo();

    const mappingType =
        editChange.userMapping && !isEmpty(editChange.userMapping)
            ? NotificationSettingType.USERS
            : NotificationSettingType.ROLES;

    const mappingValue =
        mappingType === NotificationSettingType.USERS ? editChange.userMapping : editChange.roleMapping;

    const isFileDocumentClass = documentClass === DocumentClass.FILE;

    const notificationReceiverTypesFiltered = notificationReceiverTypes.filter((notificationReceiverType) =>
        isFileDocumentClass ? notificationReceiverType !== NotificationReceiverType.READ_ACK : true
    );

    const gridLayout = getGridLayout();

    return (
        <Grid className="change-form" container={true}>
            <div className={`change-content ${isFileDocumentClass ? 'inside-tab' : ''}`}>
                {notificationInfo !== '' && <div className="change-notification-info">{notificationInfo}</div>}
                <Grid
                    className="change-form-information"
                    xs={gridLayout.changeInfoForm.xs}
                    md={gridLayout.changeInfoForm.md}
                    lg={gridLayout.changeInfoForm.lg}
                    xl={gridLayout.changeInfoForm.xl}
                    item={true}
                >
                    <Typography data-testid={'file-notification-change-title'} variant="h5">
                        {t('workflow.tools.change.form.formTitle')}
                    </Typography>
                    <ChangeInfoForm
                        summary={editChange.summary}
                        descriptionText={editChange.descriptionText}
                        hasDescription={!isFileDocumentClass}
                        onChange={update}
                        disabled={disabled}
                    />
                </Grid>

                <Grid
                    className="change-form-notifications"
                    xs={gridLayout.notificationSettingsTable.xs}
                    md={gridLayout.notificationSettingsTable.md}
                    lg={gridLayout.notificationSettingsTable.lg}
                    xl={gridLayout.notificationSettingsTable.xl}
                    item={true}
                >
                    <Typography
                        data-testid={'file-notification-mapper-title'}
                        variant="h5"
                        className={classes.mapperTitle}
                    >
                        {t('workflow.tools.change.form.mapperTitle')}
                    </Typography>
                    <NotificationSettingsTable
                        value={mappingValue}
                        onChange={update}
                        notificationReceiverTypes={notificationReceiverTypesFiltered}
                        mappingType={mappingType}
                        disabled={disabled}
                    />
                </Grid>
            </div>

            {getFormActions()}
        </Grid>
    );
};
