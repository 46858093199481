import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import {
    combineStrings,
    ContentSchema,
    ContextType,
    IApiContextVersion,
    IApiModuleVersion,
    IApplicationStore,
    LinkUrlUtils,
    modulesSelector,
} from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../interfaces';

interface ModuleVersionContentBaseViewProps {
    doc: IApiContextVersion;
    moduleVersion: IApiModuleVersion;
}

const getCleanModuleVersionContent = (
    doc: IApiContextVersion,
    moduleVersion: IApiModuleVersion,
    currentContextType: ContextType
) => {
    return `<div class="${combineStrings([
        'document-content',
        currentContextType?.contentSchema === ContentSchema.YONDER ? 'fr-view' : '',
        doc.type,
    ])}">${moduleVersion.content ? moduleVersion.content : ''}</div>`;
};

export const ModuleVersionContentBaseView: React.FunctionComponent<ModuleVersionContentBaseViewProps> = ({
    moduleVersion,
    doc,
}) => {
    const module = useSelector((state: IApplicationStore) =>
        modulesSelector.moduleByContextVersionOid(state, moduleVersion?.moduleOid, doc?.oid)
    );

    const { moduleLinks } = useSelector((state: IWebApplicationStore) => {
        return {
            moduleLinks: state.links.modules[moduleVersion?.moduleOid] ?? [],
        };
    });

    const { contextTypes } = useSelector((state: IApplicationStore) => ({
        ...state.docs,
    }));

    const currentContextType = useMemo(
        () => contextTypes.find((contextType) => contextType.oid === doc?.type),
        [contextTypes]
    );

    const uid = uuidV4();

    const div = useRef<HTMLDivElement>(null);

    const prepareInternalLinks = (node: Element) => {
        if (node) {
            node.querySelectorAll('a.module-link').forEach((link) => {
                if (link) {
                    LinkUrlUtils.prepareInternalLinks(link, module?.links || moduleLinks || []);
                }
            });
        }
    };

    const prepareLinks = () => {
        if (div.current) {
            div.current.innerHTML = getCleanModuleVersionContent(doc, moduleVersion, currentContextType);
            prepareInternalLinks(div.current);
        }
    };

    useLayoutEffect(() => {
        prepareLinks();
    }, [moduleVersion]);

    return <div className="content module-version-content cr-view" id={uid} ref={div} />;
};
