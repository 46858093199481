import React from 'react';
import { Tab as MaterialTab, Tabs as MaterialTabs } from '@material-ui/core';
import { ChangeRequestBaseTab } from '@yonder-mind/ui-core';
import { SwitchableTab } from './ChangeRequestWorkflowTabView';
import { useTranslation } from 'react-i18next';

interface ChangeRequestBaseTabsProps {
    selectedBaseTab: ChangeRequestBaseTab;
    setSelectedBaseTab: (tab: ChangeRequestBaseTab) => void;
    renderTabLabel: (name: string, tabType: ChangeRequestBaseTab) => React.ReactNode;
    renderKey: number;
    windowWidth: number;
    isEditingTextProposal: boolean;
}

export const ChangeRequestBaseTabs: React.FC<ChangeRequestBaseTabsProps> = ({
    selectedBaseTab,
    setSelectedBaseTab,
    renderTabLabel,
    renderKey,
    windowWidth,
    isEditingTextProposal,
}) => {
    const { t } = useTranslation();

    const handleActualTabClick = (event: React.MouseEvent<{}>) => {
        if (isEditingTextProposal) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const handleBaseTabClick = (_event: React.ChangeEvent<{}>, newValue: ChangeRequestBaseTab) => {
        setSelectedBaseTab(newValue);
    };

    const switchableBaseTabs: SwitchableTab[] = [
        {
            key: ChangeRequestBaseTab.ACTUAL,
            label: renderTabLabel(t('workflow.changeRequest.tabs.actual'), ChangeRequestBaseTab.ACTUAL),
            dataTestId: 'changeRequestSplitViewTabActual',
            className: 'actual-tab',
            disabled: isEditingTextProposal,
        },
        {
            key: ChangeRequestBaseTab.PROPOSAL,
            label: renderTabLabel(t('workflow.changeRequest.tabs.proposal'), ChangeRequestBaseTab.PROPOSAL),
            dataTestId: 'changeRequestSplitViewTabProposal',
            className: 'proposal-tab',
            disabled: false,
        },
        {
            key: ChangeRequestBaseTab.ACTIVITY,
            label: renderTabLabel(t('workflow.changeRequest.tabs.activity'), ChangeRequestBaseTab.ACTIVITY),
            dataTestId: 'changeRequestSplitViewTabActivity',
            className: 'activity-tab',
            disabled: false,
        },
    ];

    return (
        <MaterialTabs
            className="tabs-container left-tabs"
            variant="scrollable"
            value={selectedBaseTab}
            indicatorColor="primary"
            onChange={handleBaseTabClick}
            key={`left-tab-${renderKey}-${windowWidth}`}
        >
            {switchableBaseTabs.map(({ key, label, className, dataTestId, disabled }) => (
                <MaterialTab
                    data-testid={dataTestId}
                    className={className}
                    value={key}
                    label={label}
                    key={key}
                    disabled={disabled}
                    onClick={key === ChangeRequestBaseTab.ACTUAL ? handleActualTabClick : undefined}
                />
            ))}
        </MaterialTabs>
    );
};
