import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import {
    IWorkflowActivity,
    IWorkflowHistoricActivity,
    IWorkflowRevisionHistoric,
    userSelector,
} from '@yonder-mind/ui-core';
import { useWebsocket, useWorkflow } from '../../../../context';
import { WorkflowTopic } from '../../../../config';
import { WorkflowTool } from '../WorkflowTool';
import { DiscussionBody } from './DiscussionBody';
import { DiscussionFooter } from './DiscussionFooter';
import { useSelector } from 'react-redux';

interface IProps {
    activity?: IWorkflowActivity | IWorkflowHistoricActivity | IWorkflowRevisionHistoric;
}

export const Comments: React.FC<IProps> = ({ activity }) => {
    const { actions, comments: commentsByProcess, permissions } = useWorkflow('process');
    const websocket = useWebsocket();
    const { t } = useTranslation();

    const userSettings = useSelector(userSelector.userSettings);
    const [activeGroup, setActiveGroup] = React.useState(0);

    React.useEffect((): any => {
        if (activity) {
            const { processInstanceId: activityProcessInstanceId } = activity;
            const topic = `${WorkflowTopic.COMMENT}/${activityProcessInstanceId}`;
            websocket.subscribeToTopic(topic);
            actions.requestCommentsOfProcess(activityProcessInstanceId);
            actions.requestCommentPermission(activityProcessInstanceId);
            return () => websocket.unsubscribeFromTopic(topic);
        }
    }, [activity?.processInstanceId]);

    if (!activity) {
        return (
            <WorkflowTool tool="discussion">
                {!activity && <Typography>{t('workflow.tools.discussion.noActivity')}</Typography>}
            </WorkflowTool>
        );
    }

    const canComment = permissions[activity ? activity.processInstanceId : 0];
    const commentGroups = commentsByProcess[activity ? activity.processInstanceId : 0];

    const sendComment = (message: string) => {
        if (activity) {
            actions.writeCommentOnProcess(activity.processInstanceId, message);
        }
    };

    const scroll = (e: React.UIEvent<HTMLDivElement>) => {
        const groupsElement: HTMLDivElement = e.currentTarget;

        const groupElements: HTMLCollectionOf<any> = groupsElement.getElementsByClassName('comment-group');

        const activeIndex = Array.from(groupElements).reduce(
            (acc: number, groupElement: HTMLElement, index: number) => {
                if (groupElement.offsetTop - groupsElement.scrollTop <= 0) {
                    return index;
                }
                return acc;
            },
            0
        );

        setActiveGroup(activeIndex);
    };

    return (
        <WorkflowTool tool="discussion">
            <DiscussionBody
                activeGroup={activeGroup}
                discussion={commentGroups}
                username={userSettings.username}
                onScroll={scroll}
            />
            <DiscussionFooter canComment={canComment} onSend={sendComment} />
        </WorkflowTool>
    );
};
