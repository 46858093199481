import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { NotificationsOutlined } from '@material-ui/icons';
import {
    changesActions,
    ChangesType,
    CRName,
    docsActions,
    DocumentClass,
    Empty,
    IApiChange,
    IApiContextVersion,
    IApiContextVersionChange,
    IApiModuleVersion,
    IApiModuleVersionChange,
    IApplicationStore,
    IDocumentStatus,
    IWorkflowActivity,
    IWorkflowHistoricActivity,
    IWorkflowRevisionHistoric,
    modulesSelector,
    Spinner,
    WorkflowUtils,
    YonderButton,
    YonderDialog,
} from '@yonder-mind/ui-core';
import { crActions, useSelectorUiWeb } from '../../../../store';
import { WorkflowTool } from '../WorkflowTool';
import { ChangeForm } from './ChangeForm';

interface ChangeProps {
    type: ChangesType;
    nextModuleVersion?: IApiModuleVersion | null;
    activity?: IWorkflowActivity | IWorkflowHistoricActivity | IWorkflowRevisionHistoric;
    change?: IApiContextVersionChange | IApiModuleVersionChange | null;
    moduleOid?: string;
    moduleVersionOid?: string;
    draftContextVersionOid?: string;
    contextVersion?: IApiContextVersion;
    contextVersions?: IApiContextVersion[];
}

function canAddChange(
    isContextType: boolean,
    nextVersion: IApiContextVersion | IApiModuleVersion,
    activity?: IWorkflowActivity | IWorkflowHistoricActivity | IWorkflowRevisionHistoric
): boolean {
    if (!activity || !nextVersion || nextVersion.status !== IDocumentStatus.DRAFT) {
        return false;
    }

    if (isContextType) {
        return true;
    }

    const workflowActivity = activity as IWorkflowActivity;
    if (!workflowActivity.activityName) {
        return true;
    }

    return [CRName.ADD_MODULE, CRName.EDIT_CONTENT].includes(workflowActivity.activityName as CRName);
}

export const Change: React.FC<ChangeProps> = ({
    activity,
    nextModuleVersion,
    moduleOid,
    moduleVersionOid,
    contextVersions,
    contextVersion,
    ...props
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isContextType = props.type === 'context';

    const nextContextVersion =
        contextVersions.find((contextVersion) => contextVersion.status === IDocumentStatus.DRAFT) || contextVersions[0];

    const draftVersionOid = isContextType ? nextContextVersion?.oid : nextModuleVersion?.oid;

    const currentAndPreviousModuleVersionByModuleOid = useSelector((state: IApplicationStore) =>
        modulesSelector.currentAndPreviousModuleVersionByModuleOid(state, moduleOid)
    );
    const currentAndPreviousModuleVersionByModuleVersionOid = useSelector((state: IApplicationStore) =>
        modulesSelector.currentAndPreviousModuleVersionByModuleVersionOid(state, moduleVersionOid)
    );
    const { isLoading, importJobHasChanges } = useSelectorUiWeb((state) => {
        return {
            isLoading: state.changes.isLoading || state.workflow.changeRequest.isLoadingImportJobHasChanges,
            importJobHasChanges: state.workflow.changeRequest.importJobHasChanges,
        };
    });
    const [hasChanges, setHasChanges] = useState(false);
    const [change, setChange] = useState<IApiChange>(props.change);

    useEffect(() => {
        setChange(props.change);
    }, [props.change]);

    useEffect(() => {
        if (activity && draftVersionOid) {
            dispatch(changesActions.requestChanges(props.type, draftVersionOid, activity.id));
        }
    }, [draftVersionOid]);

    const [showDeleteConfirmation, setDeleteConfirmationVisibility] = React.useState(false);

    const isCurrentContextVersionTheNextVersion = isContextType
        ? contextVersion?.oid === nextContextVersion?.oid
        : true;

    const isContextVersionReadyToAddChange =
        !importJobHasChanges &&
        isCurrentContextVersionTheNextVersion &&
        canAddChange(isContextType, nextContextVersion, activity);

    const createChange = () => {
        if (isContextType) {
            dispatch(
                changesActions.addChange(props.type, draftVersionOid, {
                    revisionWorkflowId: activity.id,
                    revisionWorkflowLabel: activity.variables.REVISION_LABEL ? activity.variables.REVISION_LABEL : '',
                    summary: '',
                    descriptionText: '',
                } as any)
            );
        } else {
            const [crModuleVersion] = moduleOid
                ? currentAndPreviousModuleVersionByModuleOid
                : currentAndPreviousModuleVersionByModuleVersionOid;
            const { oid } = crModuleVersion;

            dispatch(
                changesActions.addChange(props.type, oid, {
                    changeRequestId: activity.id,
                    changeRequestLabel: activity.variables.CHANGE_REQUEST_LABEL,
                    changeContextVersionOid: WorkflowUtils.getContextVersionToEditOid(activity.variables),
                    summary: '',
                    descriptionText: '',
                    severity: activity.variables.SEVERITY,
                } as any)
            );
        }
    };

    const updateChange = (changeUpdated: Partial<IApiChange>) => {
        const updatedChange = {
            ...change,
            ...changeUpdated,
        };
        setHasChanges(JSON.stringify(props.change) !== JSON.stringify(updatedChange));
        setChange(updatedChange);
    };

    const saveChange = () => {
        if (activity && draftVersionOid && change) {
            dispatch(
                changesActions.updateChange(props.type, draftVersionOid, {
                    ...props.change,
                    ...change,
                })
            );
            setHasChanges(false);
        }
    };

    const confirmDelete = () => {
        setDeleteConfirmationVisibility(true);
    };

    const deleteConfirmed = () => {
        if (draftVersionOid && change) {
            dispatch(changesActions.deleteChange(props.type, draftVersionOid, change.oid));
        }

        setDeleteConfirmationVisibility(false);
    };

    const deleteCanceled = () => {
        setDeleteConfirmationVisibility(false);
    };

    const canAddNewChange =
        !activity.childActivityInstances ||
        activity.childActivityInstances.length == 0 ||
        activity.childActivityInstances[0].activityId !== 'Task_Waitlist';

    const getActions = (): Array<{ name: string; onClick: () => any }> => {
        if (importJobHasChanges && !change) {
            return props.type === 'module'
                ? [
                      {
                          name: t(`workflow.tools.change.close.refresh`),
                          onClick: () => {
                              moduleOid
                                  ? dispatch(docsActions.moduleVersionsByModuleOidRequested(moduleOid))
                                  : dispatch(docsActions.moduleVersionsByModuleVersionOidRequested(moduleVersionOid));
                              dispatch(
                                  crActions.contextVersionImportJobHasChangesRequested(props.draftContextVersionOid)
                              );
                          },
                      },
                  ]
                : [];
        }

        if (!isContextVersionReadyToAddChange) {
            return [];
        }

        if (!change && canAddNewChange) {
            return [{ name: t('workflow.tools.change.add'), onClick: createChange }];
        }

        return canAddNewChange
            ? [
                  { name: t('form.actions.delete'), onClick: confirmDelete },
                  { name: t('form.actions.save'), onClick: saveChange },
              ].filter((action) => !!action)
            : [];
    };

    const getFormLabels = () => {
        return [
            <Typography align="center" children={t('workflow.tools.change.noChange')} key="title" />,
            ...(canAddNewChange
                ? [
                      <Typography
                          align="center"
                          children={t('workflow.tools.change.clickAdd', {
                              action: t('workflow.tools.change.add'),
                          })}
                          key="info"
                      />,
                  ]
                : []),
        ];
    };

    const changeContextVersionOid = !props.change
        ? null
        : isContextType
        ? (props.change as IApiContextVersionChange).contextVersionOid
        : (props.change as IApiModuleVersionChange).masterContextVersionOid;

    const contextVersionToEditStatus = contextVersions?.find(
        (contextVersion) => contextVersion.oid === changeContextVersionOid
    )?.status;

    return (
        <WorkflowTool tool="change">
            <YonderDialog
                isOpen={showDeleteConfirmation}
                title={t('workflow.tools.change.delete.confirm.title')}
                content={t('workflow.tools.change.delete.confirm.content')}
                primaryAction={t('form.actions.confirm')}
                secondaryAction={t('form.actions.cancel')}
                onConfirm={deleteConfirmed}
                onCancel={deleteCanceled}
                onClose={deleteCanceled}
            />

            {isLoading ? (
                <Spinner />
            ) : change ? (
                <ChangeForm
                    actions={getActions()}
                    change={change}
                    changeContextVersionOid={changeContextVersionOid}
                    updateChange={updateChange}
                    hasChanges={hasChanges}
                    documentClass={DocumentClass.DOCUMENT}
                    disabled={contextVersionToEditStatus === IDocumentStatus.RELEASED}
                    contextVersions={contextVersions}
                />
            ) : (
                <>
                    <>
                        {!isContextVersionReadyToAddChange ? (
                            <Empty
                                icon={<NotificationsOutlined color={'primary'} />}
                                text={<Typography children={t(`workflow.tools.change.close.${props.type}`)} />}
                            />
                        ) : (
                            <Empty icon={<NotificationsOutlined color={'primary'} />} text={getFormLabels()} />
                        )}
                        <div className="change-form-actions">
                            {getActions().map((action, index, array) => (
                                <YonderButton
                                    variant={index === array.length - 1 ? 'contained' : 'text'}
                                    children={action.name}
                                    onClick={action.onClick}
                                    key={index}
                                    disabled={contextVersionToEditStatus === IDocumentStatus.RELEASED}
                                />
                            ))}
                        </div>
                    </>
                </>
            )}
        </WorkflowTool>
    );
};
