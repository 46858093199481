import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    authSelector,
    combineStrings,
    DocumentRevisionWorkflowTab,
    IApiContextVersion,
    IApiContextVersionChange,
    IDocumentStatus,
    IQueryParam,
    ITab,
    IWorkflowActivity,
    IWorkflowRevisionHistoric,
    SnackBar,
    Tabs,
    useChanges,
    useHistory,
    WorkflowUtils,
} from '@yonder-mind/ui-core';
import { Paper, Typography } from '@material-ui/core';
import { WorkflowTopic } from '../../../config';
import { useWebsocket, useWorkflow } from '../../../context';
import { Change, Comments } from '../Tools';
import { RevisionWorkflowSidebar } from './RevisionWorkflowSidebar';
import { StatusNotes } from '../Tools/StatusNotes';
import { HistoryLog } from '../../HistoryLog/HistoryLog';
import { useSelector } from 'react-redux';

interface IProps {
    document: IApiContextVersion;
    revision?: IWorkflowActivity;
    workflowTab?: DocumentRevisionWorkflowTab;
    contextVersions?: IApiContextVersion[];
}

export const RevisionWorkflow: React.FC<IProps> = ({ document, revision, workflowTab, contextVersions }) => {
    const { t } = useTranslation();
    const { setUrlParam } = useHistory();
    const { actions, revisionHistorics, apiErrors } = useWorkflow('revision');
    const { actions: processActions, apiErrors: processApiErrors } = useWorkflow('process');
    const websocket = useWebsocket();
    const changes = useChanges();

    const hasHistoryViewRole = useSelector(authSelector.hasHistoryViewRole);
    const [activeTab, setActiveTab] = useState<DocumentRevisionWorkflowTab | undefined>(workflowTab);
    const [revisionHistoric, setRevisionHistoric] = useState<IWorkflowRevisionHistoric | undefined>(undefined);

    const [change, setChange] = useState<IApiContextVersionChange | null>(null);

    const documentRevision = revisionHistoric || revision;

    const nextContextVersion =
        contextVersions.find((contextVersion) => contextVersion.status === IDocumentStatus.DRAFT) || contextVersions[0];

    useEffect(() => {
        websocket.subscribeToTopic(WorkflowTopic.REVISION);
        return () => websocket.unsubscribeFromTopic(WorkflowTopic.REVISION);
    }, []);

    useEffect(() => {
        if (documentRevision && nextContextVersion) {
            const foundChange = (changes.documentChanges[nextContextVersion.oid] || []).find(
                (documentChange) => documentChange.revisionWorkflowId === documentRevision.id
            );
            setChange(foundChange);
        }
    }, [documentRevision, nextContextVersion, changes]);

    React.useEffect(() => {
        if (!revision) {
            actions.requestRevisionHistorics(document.oid);
        }
    }, [revision]);

    React.useEffect(() => {
        if (!revision) {
            setRevisionHistoric(revisionHistorics[document.oid]);
        } else {
            setRevisionHistoric(undefined);
        }
    }, [revision, revisionHistorics]);

    const status = revisionHistoric
        ? WorkflowUtils.getStatus(revisionHistoric, t)
        : revision
        ? WorkflowUtils.getStatus(revision, t)
        : '';

    const subTitle = (
        <>
            <Typography
                className={combineStrings([
                    'secondary',
                    revision && revision.userHasTasks ? 'user-has-tasks' : null,
                    revisionHistoric && WorkflowUtils.isCompleted(revisionHistoric) ? 'completed' : null,
                ])}
            >
                {status}
            </Typography>
        </>
    );

    const tabs: ITab[] = [
        {
            key: DocumentRevisionWorkflowTab.OVERVIEW,
            name: t('workflow.tools.overview.title'),
            dataTestId: '',
            component: (
                <StatusNotes
                    type="revision"
                    processInstanceId={
                        revisionHistoric
                            ? revisionHistoric.processInstanceId
                            : revision
                            ? revision.processInstanceId
                            : undefined
                    }
                />
            ),
        },
        {
            key: DocumentRevisionWorkflowTab.DISCUSSION,
            name: t('workflow.tools.discussion.title'),
            dataTestId: '',
            component: <Comments activity={documentRevision} />,
        },
        {
            key: DocumentRevisionWorkflowTab.CHANGES,
            name: t('workflow.tools.change.title'),
            dataTestId: '',
            component: (
                <Change
                    type={'context'}
                    activity={documentRevision}
                    change={change}
                    key="change"
                    contextVersion={document}
                    contextVersions={contextVersions}
                />
            ),
        },
        ...(hasHistoryViewRole
            ? [
                  {
                      key: DocumentRevisionWorkflowTab.HISTORY_LOG,
                      name: t('workflow.tools.historyLog.title'),
                      dataTestId: '',
                      component: (
                          <div className={'history-log-workflow-tab'}>
                              <HistoryLog contextVersionOid={document.oid} minWidth={570} />
                          </div>
                      ),
                  },
              ]
            : []),
    ];

    const onChange = (index: number) => {
        const toolTab: ITab = tabs[index];
        setActiveTab(toolTab.key as DocumentRevisionWorkflowTab);
        setUrlParam(IQueryParam.WORKFLOW_TAB, toolTab.key.toLowerCase());
    };

    const removeApiError = (index: number) => {
        const filteredErrors = [...apiErrors];
        filteredErrors.splice(index, 1);
        actions.apiErrorReset(filteredErrors);
    };

    const removeProcessApiError = (index: number) => {
        const filteredErrors = [...processApiErrors];
        filteredErrors.splice(index, 1);
        processActions.apiErrorReset(filteredErrors);
    };

    return (
        <div className="layout--revision">
            <RevisionWorkflowSidebar
                title={document ? document.title : ''}
                subTitle={subTitle && <div className="subtitle" children={subTitle} />}
                document={document}
                activity={documentRevision}
            />
            <div className="layout--workflow__content revision-content">
                {documentRevision ? (
                    <Paper className="paper">
                        <Tabs tabs={tabs} onChange={onChange} value={activeTab} />
                    </Paper>
                ) : null}
            </div>
            {apiErrors.map((error, index) => (
                <SnackBar
                    key={index}
                    isOpen={true}
                    message={t('workflow.revision.errors.apiError', {
                        message: error.message,
                    })}
                    variant="error"
                    position="fixed"
                    onClose={() => removeApiError(index)}
                />
            ))}
            {processApiErrors.map((error, index) => (
                <SnackBar
                    key={index}
                    isOpen={true}
                    message={t('workflow.revision.errors.apiError', {
                        message: error.message,
                    })}
                    variant="error"
                    position="fixed"
                    onClose={() => removeProcessApiError(index)}
                />
            ))}
        </div>
    );
};
