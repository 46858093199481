import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ChatBubbleOutline } from '@material-ui/icons';
import { combineStrings, Empty, IWorkflowComment } from '@yonder-mind/ui-core';
import { CommentGroup } from './CommentGroup';

interface IProps {
    onScroll: (e: React.UIEvent<HTMLDivElement>) => any;
    discussion: { [taskId: string]: IWorkflowComment[] };
    activeGroup: number;
    username: string;
}

const countComments = (commentGroups?: { [status: string]: IWorkflowComment[] }) => {
    return Object.values(commentGroups || {}).reduce((acc, group) => acc + 1 + group.length, 0);
};

export const DiscussionBody: React.FC<IProps> = ({ activeGroup, discussion, onScroll, username }) => {
    const scrollContainer = React.useRef<HTMLDivElement>(null);
    const scrollElement = React.useRef<HTMLDivElement>(null);

    const [isAttached, setAttached] = React.useState(true);

    React.useLayoutEffect(() => {
        if (discussion && scrollContainer.current && scrollElement.current) {
            if (isAttached) {
                scrollContainer.current.scrollTo({
                    top: scrollElement.current.scrollHeight + scrollElement.current.offsetTop * 2,
                });
            }
        }
    }, [discussion ? countComments(discussion) : 0]);

    const { t } = useTranslation();

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const { clientHeight, scrollHeight, scrollTop } = e.currentTarget;

        if (clientHeight + scrollTop === scrollHeight) {
            setAttached(true);
        } else {
            setAttached(false);
        }

        onScroll(e);
    };

    const hasDiscussion = discussion && Object.values(discussion || {}).length > 0;
    const classNames = combineStrings(['comment-groups', hasDiscussion ? null : 'empty-groups']);

    return (
        <div className="discussion-body" ref={scrollContainer} onScroll={handleScroll}>
            <div className={classNames} ref={scrollElement}>
                {hasDiscussion ? (
                    <>
                        {Object.entries(discussion).map(([group, comments], i) => (
                            <CommentGroup
                                {...{ comments, group }}
                                userId={username}
                                active={activeGroup === i}
                                key={group || i}
                            />
                        ))}
                    </>
                ) : (
                    <Empty
                        className="empty-discussion"
                        icon={<ChatBubbleOutline color={'primary'} />}
                        text={t('workflow.tools.discussion.noComments')}
                    />
                )}
            </div>
        </div>
    );
};
