import React, { useState } from 'react';
import { Tab as MaterialTab, Tabs as MaterialTabs, Typography } from '@material-ui/core';
import {
    ChangeRequestUpcomingTab,
    IWorkflowActivity,
    IWorkflowHistoricActivity,
    WorkflowUtils,
    YonderDialog,
} from '@yonder-mind/ui-core';
import { SwitchableTab } from './ChangeRequestWorkflowTabView';
import { useTranslation } from 'react-i18next';
import { useWorkflow } from '../../../context';

interface ChangeRequestUpcomingTabsProps {
    selectedUpcomingTab: ChangeRequestUpcomingTab;
    changeRequest: IWorkflowActivity | IWorkflowHistoricActivity;
    setSelectedUpcomingTab: (tab: ChangeRequestUpcomingTab) => void;
    renderTabLabel: (name: string, tabType: ChangeRequestUpcomingTab) => React.ReactNode;
    unSavedChanges: boolean;
    onUnsavedChanges: (unsavedChanges: boolean) => void;
    renderKey: number;
    windowWidth: number;
}

export const ChangeRequestUpcomingTabs: React.FC<ChangeRequestUpcomingTabsProps> = ({
    selectedUpcomingTab,
    setSelectedUpcomingTab,
    changeRequest,
    renderTabLabel,
    unSavedChanges,
    onUnsavedChanges,
    renderKey,
    windowWidth,
}) => {
    const { t } = useTranslation();

    const { actions } = useWorkflow('cr');

    const [showChangeTabConfirmation, setShowChangeTabConfirmation] = useState(false);
    const [pendingTab, setPendingTab] = useState<ChangeRequestUpcomingTab | null>(null);

    const contextVersionToEditOid = WorkflowUtils.getContextVersionToEditOid(changeRequest.variables);
    const editModuleVersionOid = WorkflowUtils.getEditModuleVersionOid(changeRequest.variables);

    const handleUpcomingTabClick = (_event: React.ChangeEvent<{}>, newValue: ChangeRequestUpcomingTab) => {
        if (newValue === ChangeRequestUpcomingTab.NOTIFICATIONS && unSavedChanges) {
            setShowChangeTabConfirmation(true);
            setPendingTab(newValue);
            return;
        }
        setSelectedUpcomingTab(newValue);
    };

    const handleConfirmChangeTab = () => {
        actions.cancelEditCRContent(changeRequest.processInstanceId, contextVersionToEditOid, editModuleVersionOid);
        onUnsavedChanges(false);
        setShowChangeTabConfirmation(false);
        setSelectedUpcomingTab(pendingTab);
    };

    const handleCancelChangeTab = () => {
        setShowChangeTabConfirmation(false);
        setPendingTab(null);
    };

    const switchableUpcomingTabs: SwitchableTab[] = [
        {
            key: ChangeRequestUpcomingTab.NEW_VERSION,
            label: renderTabLabel(t('workflow.changeRequest.tabs.draft'), ChangeRequestUpcomingTab.NEW_VERSION),
            dataTestId: 'changeRequestSplitViewTabDraft',
            className: 'draft-tab',
            disabled: false,
        },
        {
            key: ChangeRequestUpcomingTab.NOTIFICATIONS,
            label: renderTabLabel(
                t('workflow.changeRequest.tabs.notifications'),
                ChangeRequestUpcomingTab.NOTIFICATIONS
            ),
            dataTestId: 'changeRequestSplitViewTabNotifications',
            className: 'notifications-tab',
            disabled: false,
        },
    ];

    return (
        <>
            <MaterialTabs
                className="tabs-container draft-tabs"
                variant="scrollable"
                value={selectedUpcomingTab}
                indicatorColor="primary"
                onChange={handleUpcomingTabClick}
                key={`right-tab-${renderKey}-${windowWidth}`}
            >
                {switchableUpcomingTabs.map(({ key, label, className, dataTestId, disabled }) => (
                    <MaterialTab
                        data-testid={dataTestId}
                        className={className}
                        value={key}
                        label={label}
                        key={key}
                        disabled={disabled}
                    />
                ))}
            </MaterialTabs>
            <YonderDialog
                isOpen={showChangeTabConfirmation}
                title={<Typography variant="h6">{t('workflow.changeRequest.discardUnsavedChanges.title')}</Typography>}
                content={t('common.modals.unsavedChanges.content')}
                primaryAction={t('form.actions.ok')}
                secondaryAction={t('form.actions.cancel')}
                onConfirm={handleConfirmChangeTab}
                onCancel={handleCancelChangeTab}
                onClose={handleCancelChangeTab}
            />
        </>
    );
};
