import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import {
    AddModule,
    combineStrings,
    docsSelector,
    EditContent,
    EditTitle,
    IListItemProps,
    IQueryParam,
    ISidebarTool,
    IWorkflowChangeRequestV2,
    IWorkflowType,
    RemoveModule,
    useHistory,
    WorkflowUtils,
    YonderListItem,
} from '@yonder-mind/ui-core';
import { crActions, useSelectorUiWeb } from '../../../../store';

type Variant = 'change-requests';

export interface IChangeRequestV2ListItemProps extends IListItemProps<Variant, IWorkflowChangeRequestV2> {
    dataTestId: string;
}

export const ChangeRequestV2ListItem: React.FC<IChangeRequestV2ListItemProps> = ({
    index,
    item,
    icon,
    onClick,
    ...listItemProps
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { setUrlParam } = useHistory();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const changeRequestParam = queryParams.get('change-request');

    const importance: string = item.variables.SEVERITY && item.variables.SEVERITY;
    const label = `${item.variables.CHANGE_REQUEST_LABEL}${importance ? ` - ${importance}` : ''}`;

    const summary = item.variables.CHANGE_REQUEST_SUMMARY || 'Summary'; // TODO: Replace

    const description = item.variables.CHANGE_REQUEST;

    const userHasTasks = !item.completed;

    let status = WorkflowUtils.getStatusV2(item, t);

    const contextVersionToEdit = useSelectorUiWeb((state) =>
        docsSelector.getDocumentVersion(state, WorkflowUtils.getContextVersionToEditOid(item.variables))
    );

    const isCrReleased = WorkflowUtils.isReleasedV2(item, contextVersionToEdit);

    if (isCrReleased) {
        status = t('workflow.activityId.EndEvent_Released');
    }

    const activeWorkflowTab = isCrReleased
        ? 'workflow.released'
        : status === 'In Waitlist'
        ? 'workflow.waitlist'
        : 'workflow.active';

    const handleClick = () => {
        if (onClick) {
            onClick(item, index);
        }

        dispatch(crActions.calculatedDiffClear());

        setUrlParam(IQueryParam.CHANGE_REQUEST, item.processInstanceId);
        setUrlParam(IQueryParam.SIDEBAR_TOOL, ISidebarTool.WORKFLOW);
        setUrlParam(IQueryParam.SIDEBAR_TOOL_TAB, activeWorkflowTab);
    };

    const getWorkflowIcon = () => {
        switch (item.processDefinitionKey) {
            case IWorkflowType.EDIT_CONTENT:
                return <EditContent />;
            case IWorkflowType.EDIT_TITLE:
                return <EditTitle />;
            case IWorkflowType.ADD_MODULE:
                return <AddModule />;
            case IWorkflowType.DELETE_MODULE:
                return <RemoveModule />;
            default:
                return icon;
        }
    };

    return (
        <YonderListItem
            {...listItemProps}
            className="change-request__list-item"
            icon={getWorkflowIcon()}
            primary={summary}
            secondary={description}
            tertiary={label}
            meta={status}
            metaClassName={combineStrings([
                userHasTasks ? 'user-has-tasks' : undefined,
                isCrReleased ? 'released' : undefined,
            ])}
            onClick={handleClick}
            button={true}
            crLayout={true}
            isSelected={changeRequestParam === item.processInstanceId}
        />
    );
};
