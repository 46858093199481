import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Edit } from '@material-ui/icons';
import {
    authSelector,
    complianceInfoActions,
    FabButton,
    IApiContextVersion,
    IApiModuleVersion,
    IWorkflowActivity,
    IWorkflowCrInEditInfo,
    IWorkflowHistoricActivity,
    LockSource,
    Spinner,
    WorkflowUtils,
} from '@yonder-mind/ui-core';
import { CRName } from '../../../../../config';
import { useWorkflow } from '../../../../../context';
import {
    IWebApplicationStore,
    IWorkflowCrEdit,
    IWorkflowCrEditContent,
    IWorkflowCrEditTitle,
} from '../../../../../interfaces';
import { crActions } from '../../../../../store';
import { ComplianceInfoBox } from '../ComplianceInfoBox/ComplianceInfoBox';
import { ComplianceInfoModal } from '../ComplianceInfoBox/ComplianceInfoModal';
import { DraftViewContentEditor } from './DraftViewContentEditor';
import { DraftViewContent } from './DraftViewContent';
import { DraftViewEditTitleDialog } from './DraftViewEditTitleDialog';

interface DraftViewProps {
    document: IApiContextVersion;
    changeRequest: IWorkflowActivity | IWorkflowHistoricActivity;
    crModuleVersion: IApiModuleVersion;
    setIsEditing: (isEditing: boolean) => void;
    isDiffingActive: boolean;
    onUnsavedChanges: (unsavedChanges: boolean) => void;
    isCRInImplementationPhase: boolean;
    isCRInOrAfterImplementationPhaseButNotRejected: boolean;
    isAddModuleCR: boolean;
    isTitleChanged: boolean;
    isEditContentCR: boolean;
    isDeleteCR: boolean;
    toggleDiffing: () => void;
}

export const DraftView: React.FC<DraftViewProps> = ({
    document,
    changeRequest,
    crModuleVersion,
    setIsEditing,
    isDiffingActive,
    isCRInImplementationPhase,
    isDeleteCR,
    isEditContentCR,
    isAddModuleCR,
    isTitleChanged,
    onUnsavedChanges,
    isCRInOrAfterImplementationPhaseButNotRejected,
    toggleDiffing,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { getCREdit, actions, draftSaved, editDraftSuccess, draftContentError, finishedLoadingDraftContent } =
        useWorkflow('cr');
    const { revisionsByContextOid } = useWorkflow('revision');

    const userInfo = useSelector(authSelector.userInfo);
    const complianceInfo = useSelector((store: IWebApplicationStore) => store.complianceInfo);

    const isEditTitleCR = WorkflowUtils.isChangeRequestTypeOf(changeRequest, CRName.EDIT_TITLE);
    const isCRAddOrEdit = [CRName.ADD_MODULE, CRName.EDIT_CONTENT, CRName.EDIT_TITLE].includes(
        WorkflowUtils.getChangeRequestType(changeRequest)
    );
    const contextVersionToEditOid = WorkflowUtils.getContextVersionToEditOid(changeRequest.variables);
    const editModuleVersionOid = WorkflowUtils.getEditModuleVersionOid(changeRequest.variables);

    const revision =
        revisionsByContextOid[document.contextOid]?.find((rev) => !rev.variables.IS_TEMPORARY_REVISION) || ({} as any);
    const { editmode } = revision.variables || ({} as any);
    const canEditContent = WorkflowUtils.canEditContent(revision, userInfo.roles);
    const isRevisionEditableInCr = editmode && (editmode as string).toLowerCase() === 'credit' && canEditContent;

    const isDraftEditable = isCRAddOrEdit && isRevisionEditableInCr && isCRInImplementationPhase;

    const [isComplianceInfoModalOpen, setIsComplianceInfoModalOpen] = useState(false);
    const [crEditTitle, setCREditTitle] = useState<IWorkflowCrEditTitle | undefined>(undefined);
    const [crEditContent, setCREditContent] = useState<IWorkflowCrEditContent | undefined>(undefined);
    const [crInEditLockInfo, setCRInEditLockInfo] = useState<IWorkflowCrInEditInfo | undefined>({
        username: 'initial',
        lockSource: 'initial',
    });
    const [isEditingCRTitle, setEditingCRTitle] = useState(false);
    const [isEditingCRContent, setEditingCRContent] = useState(false);
    const [isContentLoaded, setContentLoaded] = useState(false);
    const [isDraftVersionAvailable, setIsDraftVersionAvailable] = useState(true);

    const importJobLockData = useSelector((store: IWebApplicationStore) => store.import.importJobLock);

    const checkImportJobLock = (crEdit: IWorkflowCrEdit, isEditAvailable: boolean) => {
        if (crEdit && (crEdit.title || crEdit.content) && (isEditingCRTitle || isEditingCRContent)) {
            if (!crEdit.importJobLock) {
                setCREditTitle(crEdit.title);
                setCREditContent(crEdit.content);
                !isEditAvailable && setEditingCRContent(false);

                const { importJobLock } = crEdit.title || crEdit.content;
                if (importJobLock) {
                    const { username, lockSource } = importJobLock;
                    if (username === userInfo.preferred_username && lockSource !== LockSource.ADMIN_UI) {
                        !isEditAvailable && setCRInEditLockInfo({ username, lockSource });
                    } else {
                        setEditingCRTitle(false);
                        setEditingCRContent(false);
                        setCRInEditLockInfo({ username, lockSource });
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (crModuleVersion) {
            dispatch(complianceInfoActions.complianceInfoRequested(crModuleVersion.moduleOid));
        }
    }, [crModuleVersion]);

    useEffect(() => {
        setIsEditing(isEditingCRTitle || isEditingCRContent);
    }, [isEditingCRTitle, isEditingCRContent]);

    useEffect(() => {
        return () => {
            closeCREditContentEditor();
        };
    }, []);

    useEffect(() => {
        const importJobLock = importJobLockData?.[document?.oid];

        if (importJobLock && importJobLock.data) {
            const importJobLockUsername = importJobLock.data.username;
            const importJobLockSource = importJobLock.data.lockSource;
            if (
                importJobLockUsername !== userInfo.preferred_username ||
                (importJobLockUsername === userInfo.preferred_username && importJobLockSource === LockSource.ADMIN_UI)
            ) {
                setCRInEditLockInfo({ username: importJobLockUsername, lockSource: importJobLockSource });
            }
        } else {
            setCRInEditLockInfo(undefined);
        }
    }, [importJobLockData]);

    useEffect(() => {
        setContentLoaded(true);

        const crEdit = getCREdit(changeRequest.processInstanceId);
        const isEditContentOrAddModule = isEditContentCR || isAddModuleCR;

        if (!crEdit) {
            if (isEditContentOrAddModule) {
                setContentLoaded(false);
                if (
                    changeRequest.variables.CHANGE_REQUEST_EDIT_MODULE_VERSION_ID ||
                    changeRequest.variables.CHANGE_REQUEST_MODULE_VERSION_ID
                ) {
                    setIsDraftVersionAvailable(true);
                    actions.requestPeekCRContent(
                        changeRequest.processInstanceId,
                        contextVersionToEditOid,
                        editModuleVersionOid
                    );
                } else {
                    setIsDraftVersionAvailable(false);
                    setContentLoaded(true);
                }
            } else if (isEditTitleCR && crModuleVersion) {
                setContentLoaded(true);
                actions.requestPeekCRTitle(
                    changeRequest.processInstanceId,
                    contextVersionToEditOid,
                    crModuleVersion.moduleOid
                );
            } else if (isDeleteCR) {
                setContentLoaded(true);
            }
        } else {
            if (isEditContentOrAddModule) {
                setCREditContent(crEdit.content);
            }
            setContentLoaded(true);
        }

        checkImportJobLock(crEdit, true);
    }, [getCREdit(changeRequest.processInstanceId)]);

    useEffect(() => {
        const crEdit = getCREdit(changeRequest.processInstanceId);
        if (isEditingCRContent && crEditContent?.moduleVersionContent === undefined) {
            checkImportJobLock(crEdit, false);
        }
    }, [isEditingCRContent, crEditContent, getCREdit(changeRequest.processInstanceId)]);

    useEffect(() => {
        if (draftSaved) {
            setEditingCRContent(false);
            dispatch(crActions.contextVersionImportJobHasChangesReceived(true));
        }
    }, [draftSaved]);

    useEffect(() => {
        if (editDraftSuccess) {
            setContentLoaded(true);
            setEditingCRContent(false);
        }
    }, [editDraftSuccess]);

    useEffect(() => {
        if (finishedLoadingDraftContent && !isContentLoaded) {
            setContentLoaded(true);
        }
    }, [finishedLoadingDraftContent]);

    useEffect(() => {
        const importJobLockSource = importJobLockData?.[contextVersionToEditOid]?.data?.lockSource;
        const importJobLockUsername = importJobLockData?.[contextVersionToEditOid]?.data?.username;
        if (importJobLockSource === LockSource.ADMIN_UI && importJobLockUsername !== userInfo.preferred_username) {
            setContentLoaded(false);
            actions.requestCRContent(changeRequest.processInstanceId, contextVersionToEditOid, editModuleVersionOid);
        }
    }, [importJobLockData]);

    const startEditingCR = () => {
        if (isEditTitleCR) {
            actions.requestCRTitle(changeRequest.processInstanceId, contextVersionToEditOid, crModuleVersion.moduleOid);
            setEditingCRTitle(true);
        } else if (isEditContentCR || isAddModuleCR) {
            setContentLoaded(false);
            actions.requestCRContent(changeRequest.processInstanceId, contextVersionToEditOid, editModuleVersionOid);
            setEditingCRContent(true);
        }
    };

    const closeCREditContentEditor = () => {
        if (isEditingCRContent || isEditingCRTitle) {
            actions.cancelEditCRContent(changeRequest.processInstanceId, contextVersionToEditOid, editModuleVersionOid);
        }
        actions.resetDraftErrors();
        setEditingCRContent(false);
        onUnsavedChanges(false);
    };

    return (
        <div className="module-version module-version-item">
            {isEditingCRContent &&
            crModuleVersion &&
            crEditContent &&
            isContentLoaded &&
            crEditContent.moduleVersionContent !== undefined ? (
                <>
                    <ComplianceInfoBox
                        dataTestId="workflow-complianceInfoBox"
                        complianceInfo={complianceInfo}
                        onMoreInfoClick={() => setIsComplianceInfoModalOpen(true)}
                    />
                    <DraftViewContentEditor
                        document={document}
                        changeRequest={changeRequest}
                        crEditContent={crEditContent}
                        crModuleVersion={crModuleVersion}
                        onUnsavedChanges={onUnsavedChanges}
                        closeCREditContentEditor={closeCREditContentEditor}
                        setCREditContent={setCREditContent}
                        isDraftEditable={isDraftEditable}
                        setEditingCRContent={setEditingCRContent}
                    />
                    <ComplianceInfoModal
                        open={isComplianceInfoModalOpen}
                        onClose={() => setIsComplianceInfoModalOpen(false)}
                        complianceInfo={complianceInfo.data}
                    />
                </>
            ) : (
                <>
                    {!isContentLoaded ? (
                        <div className="empty empty-root align-center">
                            <Spinner />
                        </div>
                    ) : !isDraftVersionAvailable && !crEditContent && !isDraftEditable ? (
                        <div className="empty empty-root align-center">
                            {t('workflow.changeRequest.errors.draftVersionNotAvailable')}
                        </div>
                    ) : draftContentError && !crEditContent ? (
                        <div className="empty empty-root align-center">
                            {draftContentError.message && draftContentError.message.length > 0
                                ? draftContentError.message
                                : t('workflow.changeRequest.errors.draftVersionNotLoaded')}
                        </div>
                    ) : (
                        <DraftViewContent
                            document={document}
                            changeRequest={changeRequest}
                            crInEditLockInfo={crInEditLockInfo}
                            crModuleVersion={crModuleVersion}
                            crEditContent={crEditContent}
                            isDiffingActive={isDiffingActive}
                            toggleDiffing={toggleDiffing}
                            isCRInOrAfterImplementationPhaseButNotRejected={
                                isCRInOrAfterImplementationPhaseButNotRejected
                            }
                            isEditContentCR={isEditContentCR}
                            isCRInImplementationPhase={isCRInImplementationPhase}
                            isEditTitleCR={isEditTitleCR}
                            isTitleChanged={isTitleChanged}
                            isDeleteCR={isDeleteCR}
                            isAddModuleCR={isAddModuleCR}
                            isDraftEditable={isDraftEditable}
                        />
                    )}
                    {isDraftEditable && isContentLoaded && !draftContentError && (
                        <FabButton
                            size="small"
                            onClick={startEditingCR}
                            disabled={Boolean(crInEditLockInfo) || !isDraftEditable}
                        >
                            <Edit />
                        </FabButton>
                    )}
                </>
            )}
            {isEditingCRTitle && crEditTitle && crEditTitle.moduleVersionTitle !== undefined && (
                <DraftViewEditTitleDialog
                    changeRequest={changeRequest}
                    crEditTitle={crEditTitle}
                    setCREditTitle={setCREditTitle}
                    setEditingCRTitle={setEditingCRTitle}
                    crModuleVersion={crModuleVersion}
                />
            )}
        </div>
    );
};
