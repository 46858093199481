import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Paper } from '@material-ui/core';
import {
    docsActions,
    IApiContextVersion,
    IApiModuleVersionChange,
    IWorkflowActivity,
    IWorkflowHistoricActivity,
    LockSource,
    SnackBar,
    WorkflowUtils,
} from '@yonder-mind/ui-core';
import { useWorkflow } from '../../../context';
import { importJobActions } from '../../../store';
import { IWebApplicationStore } from '../../../interfaces';
import { ChangeRequestWorkflowTabView } from './ChangeRequestWorkflowTabView';

interface ChangeRequestWorkflowProps {
    document: IApiContextVersion;
    changeRequest: IWorkflowActivity | IWorkflowHistoricActivity;
    onUnsavedChanges: (hasChanges: boolean) => void;
    unSavedChanges: boolean;
    setEditing: (isEditing: boolean) => void;
    visibleChange: IApiModuleVersionChange | null;
}

export const ChangeRequestWorkflow: React.FC<ChangeRequestWorkflowProps> = ({
    document,
    changeRequest,
    onUnsavedChanges,
    unSavedChanges,
    setEditing,
    visibleChange,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const crModuleVersionOid = changeRequest.variables.CHANGE_REQUEST_MODULE_VERSION_ID;
    const crModuleOid = changeRequest.variables.CHANGE_REQUEST_MODULE_ID;

    const { actions, apiErrors, draftErrorSnackVisible, editDraftSuccess } = useWorkflow('cr');
    const { actions: processActions, apiErrors: processApiErrors, tasks } = useWorkflow('process');
    const { revisionsByContextOid } = useWorkflow('revision');

    const contextVersionInRevision = revisionsByContextOid[document.contextOid]?.find(
        (revision) => !revision.variables.IS_TEMPORARY_REVISION
    );
    const contextVersionToEditOid = contextVersionInRevision
        ? WorkflowUtils.getContextVersionOid(contextVersionInRevision.variables)
        : document.oid;

    const importJobLockData = useSelector((state: IWebApplicationStore) => state.import.importJobLock);
    const importJobLock = importJobLockData?.[contextVersionToEditOid];

    const [importJobLockSnackBarActive, setImportJobLockSnackBarActive] = React.useState(false);

    useEffect(() => {
        dispatch(importJobActions.importJobLockReset());
        actions.resetDraftErrors();
    }, []);

    useEffect(() => {
        if (crModuleOid) {
            dispatch(docsActions.moduleVersionsByModuleOidRequested(crModuleOid));
        } else {
            if (crModuleVersionOid) {
                dispatch(docsActions.moduleVersionsByModuleVersionOidRequested(crModuleVersionOid));
            }
        }
    }, [changeRequest?.processInstanceId]);

    useEffect(() => {
        if (tasks && Object.keys(tasks).length !== 0) {
            const processInstanceId = changeRequest?.processInstanceId;
            const currentTask = tasks?.[processInstanceId]?.[0];
            const taskName = processInstanceId && currentTask ? currentTask?.name : '';
            if (taskName === 'Retry') {
                setImportJobLockSnackBarActive(true);
                dispatch(importJobActions.importJobLockRequested(contextVersionToEditOid));
            }
        }
    }, [tasks]);

    useEffect(() => {
        if (
            importJobLockData &&
            importJobLock &&
            importJobLock.data &&
            importJobLock.data.lockSource === LockSource.ADMIN_UI
        ) {
            setImportJobLockSnackBarActive(true);
        }
    }, [importJobLockData]);

    const removeApiError = (index: number) => {
        const filteredErrors = [...apiErrors];
        filteredErrors.splice(index, 1);
        actions.apiErrorReset(filteredErrors);
    };

    const removeProcessApiError = (index: number) => {
        const filteredErrors = [...processApiErrors];
        filteredErrors.splice(index, 1);
        processActions.apiErrorReset(filteredErrors);
    };

    const closeImportJobLockError = () => {
        setImportJobLockSnackBarActive(false);
    };

    return (
        <div className={'layout--change-request'}>
            <div className="layout--workflow__content">
                <Paper className="paper">
                    <ChangeRequestWorkflowTabView
                        document={document}
                        changeRequest={changeRequest}
                        setEditing={setEditing}
                        unSavedChanges={unSavedChanges}
                        onUnsavedChanges={onUnsavedChanges}
                        visibleChange={visibleChange}
                    />
                </Paper>
            </div>
            {apiErrors.map((error, index) => (
                <SnackBar
                    key={index}
                    isOpen={true}
                    message={t('workflow.revision.errors.apiError', {
                        message: error.message,
                    })}
                    variant="error"
                    position="fixed"
                    onClose={() => removeApiError(index)}
                />
            ))}
            {draftErrorSnackVisible && (
                <SnackBar
                    key="draft-error"
                    isOpen={true}
                    message={t('workflow.revision.errors.draftSaveError')}
                    variant="error"
                    position="fixed"
                    onClose={() => actions.closeDraftError()}
                />
            )}
            {editDraftSuccess && (
                <SnackBar
                    key="draft-error"
                    isOpen={true}
                    message={t('workflow.revision.errors.draftEditError')}
                    variant="error"
                    position="fixed"
                    onClose={() => actions.closeDraftError()}
                />
            )}
            {processApiErrors.map((error, index) => (
                <SnackBar
                    key={index}
                    isOpen={true}
                    message={t('workflow.revision.errors.apiError', {
                        message: error.message,
                    })}
                    variant="error"
                    position="fixed"
                    onClose={() => removeProcessApiError(index)}
                />
            ))}
            {importJobLock && importJobLock.data && (
                <SnackBar
                    key="import-lock-error"
                    isOpen={importJobLockSnackBarActive}
                    message={t('workflow.changeRequest.errors.jobLocked', {
                        username: importJobLock.data.username,
                        lockSource: importJobLock.data.lockSource,
                    })}
                    variant="warning"
                    position="fixed"
                    onClose={() => closeImportJobLockError()}
                />
            )}
        </div>
    );
};
