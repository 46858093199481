import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { IconButton } from '@material-ui/core';
import { DescriptionOutlined as Document } from '@material-ui/icons';
import {
    appSettingsActions,
    authSelector,
    ChangeRequestBaseTab,
    ChangeRequestUpcomingTab,
    changeRequestViewActions,
    combineStrings,
    ContextVersionUtils,
    docsSelector,
    Empty,
    IApiContextVersion,
    IApiModuleVersionChange,
    IApplicationStore,
    IDocumentStatus,
    IQueryParam,
    IReaderTool,
    IWorkflowActivity,
    IWorkflowHistoricActivity,
    modulesSelector,
    ModuleVersionHeader,
    ModuleVersionUtils,
    moduleViewActions,
    OutlineUtils,
    PinButton,
    routerSelectors,
    SnackBar,
    Spacer,
    Spinner,
    SplitView,
    useHistory,
    userActions,
    useSideBar,
    WorkflowUtils,
} from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../../interfaces';
import { useWorkflow } from '../../../context';
import { CRName, WorkflowCRProposalScreen } from '../../../config';
import { useSelectorUiWeb } from '../../../store';
import { ChangeRequestUtils } from '../../../utils';
import { DraftView, ModuleVersionView, TextProposalView } from './SplitViews';
import { ActivityView } from './SplitViews/ActivityView/ActivityView';
import { Change } from '../Tools';
import { ChangeRequestUpcomingTabs } from './ChangeRequestUpcomingTabs';
import { ChangeRequestBaseTabs } from './ChangeRequestBaseTabs';

interface ChangeRequestWorkflowTabViewProps {
    document: IApiContextVersion;
    changeRequest: IWorkflowActivity | IWorkflowHistoricActivity;
    setEditing: (isEditing: boolean) => void;
    unSavedChanges: boolean;
    onUnsavedChanges: (hasChanges: boolean) => void;
    visibleChange: IApiModuleVersionChange | null;
}

export interface SwitchableTab {
    key: ChangeRequestBaseTab | ChangeRequestUpcomingTab;
    label: React.ReactNode;
    dataTestId: string;
    className: string;
    disabled: boolean;
}

export const ChangeRequestWorkflowTabView: React.FC<ChangeRequestWorkflowTabViewProps> = ({
    document,
    changeRequest,
    setEditing,
    onUnsavedChanges,
    unSavedChanges,
    visibleChange,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { pushUrl } = useHistory();
    const { getCREdit } = useWorkflow('cr');
    const { handleSetActiveTool } = useSideBar();
    const { sidebarTool } = useSelector(routerSelectors.queryParams);

    const { params: routerParams } = useRouteMatch<{ documentOid: string }>();
    const { documentOid } = routerParams;
    const contextVersions = useSelectorUiWeb((state) => docsSelector.getVersions(state, documentOid));
    const hasDraftDocsAccess = useSelector(authSelector.hasDraftDocsAccess);
    const { userSettings } = useSelector((state: IWebApplicationStore) => state.user);
    const defaultChangeRequestBaseTab = userSettings.uiSettings?.defaultChangeRequestBaseTab;
    const defaultChangeRequestUpcomingTab = userSettings.uiSettings?.defaultChangeRequestUpcomingTab;
    const userInfo = useSelector(authSelector.userInfo);

    const [selectedBaseTab, setSelectedBaseTab] = useState<ChangeRequestBaseTab>(ChangeRequestBaseTab.ACTUAL);
    const [selectedUpcomingTab, setSelectedUpcomingTab] = useState<ChangeRequestUpcomingTab>(
        ChangeRequestUpcomingTab.NEW_VERSION
    );
    const [timedOut, setTimedOut] = useState<boolean>(false);
    const [isEditingTextProposal, setEditingTextProposal] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [renderKey, setRenderKey] = useState(0);

    const crModuleVersionOid = changeRequest.variables.CHANGE_REQUEST_MODULE_VERSION_ID;
    const crModuleOid = changeRequest.variables.CHANGE_REQUEST_MODULE_ID;

    useEffect(() => {
        dispatch(appSettingsActions.diffingSettingsRequested());
        dispatch(appSettingsActions.diffingProposalSettingsRequested());
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setRenderKey((prevKey) => prevKey + 1);
        }, 450);

        return () => clearTimeout(timer);
    }, [sidebarTool]);

    useEffect(() => {
        if (defaultChangeRequestBaseTab && defaultChangeRequestBaseTab !== ChangeRequestBaseTab.ACTUAL) {
            setSelectedBaseTab(defaultChangeRequestBaseTab);
        }
    }, [defaultChangeRequestBaseTab]);

    useEffect(() => {
        if (
            defaultChangeRequestUpcomingTab &&
            defaultChangeRequestUpcomingTab !== ChangeRequestUpcomingTab.NEW_VERSION
        ) {
            setSelectedUpcomingTab(defaultChangeRequestUpcomingTab);
        }
    }, [defaultChangeRequestUpcomingTab]);

    useEffect(() => {
        setEditing(isEditingTextProposal);
    }, [isEditingTextProposal]);

    const isModuleDiffingDraftActive = useSelector(
        (state: IWebApplicationStore) => state.appSettings.moduleDiffingDraft
    );
    const isModuleDiffingProposalActive = useSelector(
        (state: IWebApplicationStore) => state.appSettings.moduleDiffingProposal
    );

    const currentAndPreviousModuleVersionByModuleOid = useSelector((state: IApplicationStore) =>
        modulesSelector.currentAndPreviousModuleVersionByModuleOid(state, crModuleOid)
    );
    const currentAndPreviousModuleVersionByModuleVersionOid = useSelector((state: IApplicationStore) =>
        modulesSelector.currentAndPreviousModuleVersionByModuleVersionOid(state, crModuleVersionOid)
    );

    // TODO: These functions should be properly implemented - they get draft version and latest
    // Also CHANGE_REQUEST_EDIT_MODULE_VERSION_ID is not taken into account and should be used
    const [crModuleVersion, previousModuleVersion] = crModuleOid
        ? currentAndPreviousModuleVersionByModuleOid
        : currentAndPreviousModuleVersionByModuleVersionOid;

    const previousVersion = useSelector((state: IApplicationStore) =>
        modulesSelector.previousVersion(state, crModuleVersion?.moduleOid, crModuleVersion?.oid)
    );

    const isDeleteCR = WorkflowUtils.isChangeRequestTypeOf(changeRequest, CRName.DELETE_MODULE);
    const isEditContentCR = WorkflowUtils.isChangeRequestTypeOf(changeRequest, CRName.EDIT_CONTENT);
    const isEditTitleCR = WorkflowUtils.isChangeRequestTypeOf(changeRequest, CRName.EDIT_TITLE);
    const isAddModuleCR = WorkflowUtils.isChangeRequestTypeOf(changeRequest, CRName.ADD_MODULE);
    const useEditor = changeRequest.variables.PROPOSAL_SCREEN === WorkflowCRProposalScreen.EDITOR;
    const canEditTextProposal = WorkflowUtils.canEditTextProposal(changeRequest, userInfo.roles);
    const isCRInImplementationPhase = WorkflowUtils.isInImplementationPhase(changeRequest);
    const isCRCompleted = WorkflowUtils.isCompleted(changeRequest);
    const isCRRejected = WorkflowUtils.isObsolete(changeRequest);
    const isInApprovalPhase =
        !isCRInImplementationPhase &&
        !canEditTextProposal &&
        ChangeRequestUtils.isChangeRequestInApprovalPhase(changeRequest.childActivityInstances);

    const isCRInOrAfterImplementationPhaseButNotRejected =
        isCRInImplementationPhase || isInApprovalPhase || (isCRCompleted && !isCRRejected);

    const contextVersionToEdit = contextVersions?.find(
        (v) => v.oid === WorkflowUtils.getContextVersionToEditOid(changeRequest.variables)
    );

    const getPreviousContextVersion = () => {
        if (contextVersions?.length > 1 && contextVersionToEdit) {
            // We are getting only released ones because we can have temporary revision and regular revision open at the same time
            const versionsSorted = ContextVersionUtils.sortByLatestEffective(contextVersions).filter(
                (v) => v.status === IDocumentStatus.RELEASED
            );
            const indexOfContextVersionToEdit = versionsSorted.indexOf(contextVersionToEdit);
            if (versionsSorted.length === indexOfContextVersionToEdit + 1) {
                return undefined;
            }
            return versionsSorted[indexOfContextVersionToEdit + 1];
        }
        return undefined;
    };

    let previousContextVersion = getPreviousContextVersion();
    // If there is no previous context version use contextVersionToEdit
    if (!previousContextVersion) {
        previousContextVersion = contextVersionToEdit;
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimedOut(true);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    if (crModuleVersion === null) {
        return (
            <>
                <Spinner />
                {timedOut && (
                    <SnackBar
                        key={0}
                        isOpen={true}
                        message={t('workflow.revision.errors.notPublished')}
                        variant="warning"
                        position="fixed"
                    />
                )}
            </>
        );
    }

    const crEdit = getCREdit(changeRequest.processInstanceId);

    const getModuleVersionTitle = () => {
        if (crEdit && crEdit.title && crEdit.title.moduleVersionTitle) {
            return crEdit.title.moduleVersionTitle;
        }

        if (isDeleteCR) {
            return '';
        }

        if (changeRequest.variables.NEW_MODULE_VERSION_TITLE) {
            return changeRequest.variables.NEW_MODULE_VERSION_TITLE;
        }

        if (!contextVersionToEdit) {
            return t('reader.moduleVersion.notFound');
        }

        return contextVersionToEdit.moduleVersionOutlineInfo[crModuleVersion.moduleOid]?.moduleVersionTitle;
    };

    const moduleVersionNumbering = isDeleteCR
        ? ''
        : contextVersionToEdit && ModuleVersionUtils.getModuleVersionNumbering(contextVersionToEdit, crModuleVersion);
    const moduleVersionTitle = getModuleVersionTitle();

    // If change request is released and is content change request previous module version is not correct so we need to fix it
    const fixedPreviousModuleVersion =
        WorkflowUtils.isReleased(changeRequest, contextVersions) && isEditContentCR
            ? previousVersion
            : previousModuleVersion;

    const previousModuleVersionNumbering = isAddModuleCR
        ? ''
        : fixedPreviousModuleVersion &&
          previousContextVersion &&
          ModuleVersionUtils.getModuleVersionNumbering(previousContextVersion, fixedPreviousModuleVersion);

    const previousModuleVersionTitle = isAddModuleCR
        ? ''
        : fixedPreviousModuleVersion && previousContextVersion
        ? previousContextVersion.moduleVersionOutlineInfo[fixedPreviousModuleVersion.moduleOid]?.moduleVersionTitle ??
          t('reader.moduleVersion.notFound')
        : t('reader.moduleVersion.notFound');

    const openDoc = () => {
        handleSetActiveTool(undefined);
        const modulePath = getModulePath();
        pushUrl(`/doc/${document.oid}/${modulePath}`, [
            {
                key: IQueryParam.READER_TOOLS,
                value: IReaderTool.MODULE_VIEW,
            },
        ]);
        dispatch(changeRequestViewActions.setOpen({ isOpen: false }));
        dispatch(moduleViewActions.setOpen({ isOpen: true }));
    };

    const getModulePath = () => {
        return OutlineUtils.findModuleInOutline(document.outline, crModuleVersion.moduleOid).path.join('/');
    };

    const toggleDraftDiffing = () => {
        dispatch(appSettingsActions.saveDiffingSettingsRequested(!isModuleDiffingDraftActive));
    };

    const toggleProposalDiffing = () => {
        dispatch(appSettingsActions.saveDiffingProposalSettingsRequested(!isModuleDiffingProposalActive));
    };

    const isTitleChanged = previousModuleVersionTitle === moduleVersionTitle;

    const showTitleInDraftTab =
        (!isDeleteCR && isCRInOrAfterImplementationPhaseButNotRejected) ||
        (isEditTitleCR && isTitleChanged ? isCRInImplementationPhase : true);

    const textProposalHeaderTitle = isEditTitleCR
        ? combineStrings([moduleVersionNumbering, WorkflowUtils.getEditTitleChangeRequestProposalTitle(changeRequest)])
        : combineStrings([moduleVersionNumbering, moduleVersionTitle]);

    const actualView = {
        header: (
            <ModuleVersionHeader text={combineStrings([previousModuleVersionNumbering, previousModuleVersionTitle])} />
        ),
        content: (
            <ModuleVersionView
                document={previousContextVersion}
                changeRequest={changeRequest}
                moduleVersion={
                    previousModuleVersionTitle === t('reader.moduleVersion.notFound')
                        ? null
                        : fixedPreviousModuleVersion
                }
            />
        ),
    };

    const proposalView = {
        header: <ModuleVersionHeader text={textProposalHeaderTitle} />,
        content: (
            <TextProposalView
                onUnsavedChanges={onUnsavedChanges}
                document={previousContextVersion}
                changeRequest={changeRequest}
                crModuleVersion={crModuleVersion}
                useEditor={useEditor}
                canEditTextProposal={canEditTextProposal}
                isEditingTextProposal={isEditingTextProposal}
                isEditTitleCR={isEditTitleCR}
                isDeleteCR={isDeleteCR}
                isCRCompleted={isCRCompleted}
                isCRRejected={isCRRejected}
                setEditingTextProposal={setEditingTextProposal}
                toggleProposalDiffing={toggleProposalDiffing}
                diffingProposalActive={isModuleDiffingProposalActive}
            />
        ),
    };

    const activityView = {
        header: <ModuleVersionHeader text={textProposalHeaderTitle} />,
        content: <ActivityView changeRequest={changeRequest} />,
    };

    const draftView = {
        header: (
            <ModuleVersionHeader
                text={showTitleInDraftTab ? combineStrings([moduleVersionNumbering, moduleVersionTitle]) : ''}
            />
        ),
        content: (
            <DraftView
                document={contextVersionToEdit}
                changeRequest={changeRequest}
                crModuleVersion={
                    moduleVersionTitle === t('reader.moduleVersion.notFound') || isDeleteCR ? null : crModuleVersion
                }
                setIsEditing={setEditing}
                isDiffingActive={isModuleDiffingDraftActive}
                isTitleChanged={isTitleChanged}
                isEditContentCR={isEditContentCR}
                isAddModuleCR={isAddModuleCR}
                isDeleteCR={isDeleteCR}
                isCRInImplementationPhase={isCRInImplementationPhase}
                isCRInOrAfterImplementationPhaseButNotRejected={isCRInOrAfterImplementationPhaseButNotRejected}
                onUnsavedChanges={onUnsavedChanges}
                toggleDiffing={toggleDraftDiffing}
            />
        ),
    };

    const notificationsView = {
        header: (
            <ModuleVersionHeader
                text={showTitleInDraftTab ? combineStrings([moduleVersionNumbering, moduleVersionTitle]) : ''}
            />
        ),
        content: (
            <Change
                draftContextVersionOid={
                    changeRequest?.variables && WorkflowUtils.getContextVersionToEditOid(changeRequest.variables)
                }
                type={'module'}
                activity={changeRequest}
                change={visibleChange}
                moduleOid={crModuleOid}
                moduleVersionOid={crModuleVersionOid}
                nextModuleVersion={crModuleVersion}
                key="change"
                contextVersions={contextVersions}
            />
        ),
    };

    const getViews = () => {
        let baseView;
        let upcomingView = draftView;

        switch (selectedBaseTab) {
            case ChangeRequestBaseTab.ACTUAL:
                baseView = actualView;
                break;
            case ChangeRequestBaseTab.PROPOSAL:
                baseView = proposalView;
                break;
            case ChangeRequestBaseTab.ACTIVITY:
                baseView = activityView;
                break;
            default:
                baseView = actualView;
        }

        switch (selectedUpcomingTab) {
            case ChangeRequestUpcomingTab.NEW_VERSION:
                upcomingView = draftView;
                break;
            case ChangeRequestUpcomingTab.NOTIFICATIONS:
                upcomingView = notificationsView;
                break;
            default:
                upcomingView = draftView;
        }

        return [baseView, upcomingView];
    };

    const openDocButtonRender = () => {
        return (
            <IconButton
                component="span"
                className="open-doc"
                data-testid={'changeRequestSplitViewTabOpenDoc'}
                children={<Document />}
                onClick={openDoc}
            />
        );
    };

    const handlePinButtonClick = (key: ChangeRequestBaseTab | ChangeRequestUpcomingTab, isLeftTab: boolean) => {
        if (isLeftTab) {
            dispatch(
                userActions.updateUserSettings({
                    userSettings: {
                        ...userSettings,
                        uiSettings: {
                            ...userSettings.uiSettings,
                            defaultChangeRequestBaseTab: key.toUpperCase() as ChangeRequestBaseTab,
                        },
                    },
                })
            );
            setSelectedBaseTab(key as ChangeRequestBaseTab);
        } else {
            dispatch(
                userActions.updateUserSettings({
                    userSettings: {
                        ...userSettings,
                        uiSettings: {
                            ...userSettings.uiSettings,
                            defaultChangeRequestUpcomingTab: key.toUpperCase() as ChangeRequestUpcomingTab,
                        },
                    },
                })
            );
            setSelectedUpcomingTab(key as ChangeRequestUpcomingTab);
        }
    };

    const pinButtonRender = (key: ChangeRequestBaseTab | ChangeRequestUpcomingTab) => {
        const isLeftTab = Object.values(ChangeRequestBaseTab).includes(key as ChangeRequestBaseTab);

        return (
            <PinButton
                isPinned={isLeftTab ? defaultChangeRequestBaseTab === key : defaultChangeRequestUpcomingTab === key}
                onClickPin={() => handlePinButtonClick(key, isLeftTab)}
                dataTestId={`changeRequestSplitViewTabPinButton-${key?.toLowerCase()}`}
            ></PinButton>
        );
    };

    const renderTabLabel = (name: string, tabType: ChangeRequestBaseTab | ChangeRequestUpcomingTab) => {
        return (
            <div className={'tab-item-label'}>
                {pinButtonRender(tabType as ChangeRequestBaseTab)}
                <span>{name}</span>
                {tabType === ChangeRequestBaseTab.ACTUAL && !isAddModuleCR && openDocButtonRender()}
            </div>
        );
    };

    return !hasDraftDocsAccess ? (
        <div className="change-request__split-view">
            <Empty className="content empty" variant="text" text={t('workflow.changeRequest.errors.noViewRole')} />
        </div>
    ) : contextVersionToEdit ? (
        <div className="change-request__split-view">
            <div className="tabs-wrapper">
                <ChangeRequestBaseTabs
                    selectedBaseTab={selectedBaseTab}
                    setSelectedBaseTab={setSelectedBaseTab}
                    renderTabLabel={renderTabLabel}
                    renderKey={renderKey}
                    windowWidth={windowWidth}
                    isEditingTextProposal={isEditingTextProposal}
                />
                <Spacer />
                <ChangeRequestUpcomingTabs
                    selectedUpcomingTab={selectedUpcomingTab}
                    setSelectedUpcomingTab={setSelectedUpcomingTab}
                    changeRequest={changeRequest}
                    unSavedChanges={unSavedChanges}
                    onUnsavedChanges={onUnsavedChanges}
                    renderTabLabel={renderTabLabel}
                    renderKey={renderKey}
                    windowWidth={windowWidth}
                />
            </div>
            <SplitView className="tab-view" views={getViews()} />
        </div>
    ) : (
        <Spinner />
    );
};
