import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import {
    ChangeRequestToolTab,
    docsSelector,
    filterAndSortActions,
    FilterUtils,
    filterWorkflowTabConfig,
    IApplicationStore,
    IQueryParam,
    ISidebarTool,
    ISidebarToolTab,
    IWorkflowChangeRequestV2,
    IWorkflowStatus,
    routerSelectors,
    SortUtils,
    Spinner,
    useHistory,
    useSideBar,
    WorkflowUtils,
    YonderList,
} from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../../../interfaces';
import { ChangeRequestV2ListItem } from '../../../List/ListItemVariants/ChangeRequestV2ListItem';

interface ChangeRequestListProps {
    itemsKey: ChangeRequestToolTab;
    activeWorkflowTab: string;
    values?: {};
}

export const ChangeRequestList: React.FC<ChangeRequestListProps> = ({ itemsKey, activeWorkflowTab, values }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { activeTool, collapseIfMobile, handleSetActiveToolTab } = useSideBar();
    const { setUrlParam } = useHistory();
    const { changeRequest } = useSelector(routerSelectors.queryParams);

    const { params: routerParams } = useRouteMatch<{ documentOid: string }>();
    const { documentOid } = routerParams;
    const contextVersion = useSelector((state: IApplicationStore) => docsSelector.getDocument(state, documentOid));

    const { filteredWorkflowData } = useSelector((state: IApplicationStore) => state.filterAndSort);

    const { activeCrs, isLoading, releasedCrs } = useSelector((state: IWebApplicationStore) => {
        return {
            activeCrs: state.workflow.changeRequest.activeCRs,
            isLoading:
                itemsKey === ChangeRequestToolTab.RELEASED
                    ? state.workflow.changeRequest.isLoadingFinishedCRs
                    : state.workflow.changeRequest.isLoadingActiveCRs,
            releasedCrs: state.workflow.changeRequest.finishedCRs,
        };
    });
    const [statusesLoaded, setStatusesLoaded] = React.useState(false);

    const getWorkflowStatusesList = (changeRequestsList: IWorkflowChangeRequestV2[]) => {
        const statuses: IWorkflowStatus[] = changeRequestsList.map((cr) => {
            const status = WorkflowUtils.getStatusV2(cr, t);
            return {
                value: status,
                name: status,
            };
        });

        const uniqueStatuses = statuses.filter((obj, pos, arr) => {
            return arr.map((mapObj) => mapObj.name).indexOf(obj.name) == pos;
        });
        return uniqueStatuses;
    };

    const getWorkflowSeveritiesList = (changeRequestsList: IWorkflowChangeRequestV2[]) => {
        const severities: IWorkflowStatus[] = changeRequestsList.map((cr) => {
            const severity = cr.variables.SEVERITY;
            return {
                value: severity ? severity : '',
                name: severity ? severity : '',
            };
        });
        const uniqueSeverities = severities.filter((obj, pos, arr) => {
            if (obj.name !== '') {
                return arr.map((mapObj) => mapObj.name).indexOf(obj.name) == pos;
            }
            return null;
        });
        return uniqueSeverities;
    };

    useEffect(() => {
        dispatch(filterAndSortActions.filterAndSortWorkflowValuesRequested());
    }, []);

    const changeRequests = React.useMemo(() => {
        return itemsKey === ChangeRequestToolTab.RELEASED
            ? releasedCrs
            : activeCrs.filter((cr) => {
                  const isInWaitlist = WorkflowUtils.isInWaitListV2(cr);

                  switch (itemsKey) {
                      case ChangeRequestToolTab.ACTIVE:
                          return !isInWaitlist;
                      case ChangeRequestToolTab.WAITLIST:
                          return isInWaitlist;
                      default:
                          return false;
                  }
              });
    }, [itemsKey, activeCrs, releasedCrs, contextVersion]);

    useEffect(() => {
        if (changeRequests?.length > 0 && !statusesLoaded && activeWorkflowTab) {
            setStatusesLoaded(true);
            activeWorkflowTab === itemsKey &&
                dispatch(
                    filterAndSortActions.setFilterAndSortWorkflowStatuses(
                        contextVersion.oid,
                        getWorkflowStatusesList(changeRequests)
                    )
                );
            activeWorkflowTab === itemsKey &&
                dispatch(
                    filterAndSortActions.setFilterAndSortWorkflowSeverity(
                        contextVersion.oid,
                        getWorkflowSeveritiesList(changeRequests)
                    )
                );
            return;
        } else if (
            statusesLoaded &&
            changeRequests?.length > 0 &&
            activeWorkflowTab &&
            !isEqual(
                filteredWorkflowData[contextVersion.oid]
                    ? filteredWorkflowData[contextVersion.oid].availableStatuses
                    : [],
                getWorkflowStatusesList(changeRequests)
            )
        ) {
            activeWorkflowTab === itemsKey &&
                dispatch(
                    filterAndSortActions.setFilterAndSortWorkflowStatuses(
                        contextVersion.oid,
                        getWorkflowStatusesList(changeRequests)
                    )
                );
            activeWorkflowTab === itemsKey &&
                dispatch(
                    filterAndSortActions.setFilterAndSortWorkflowSeverity(
                        contextVersion.oid,
                        getWorkflowSeveritiesList(changeRequests)
                    )
                );
        } else {
            const activeSort =
                filteredWorkflowData[contextVersion.oid] && filteredWorkflowData[contextVersion.oid].activeSort;
            const hasActiveSort = Object.values(activeSort ? activeSort : {}).some((val) => val.length);
            !hasActiveSort && dispatch(filterAndSortActions.workflowSortByCriteriaRequested('ASC', 'number'));
        }
    }, [changeRequests, activeWorkflowTab]);

    useEffect(() => {
        const activeSort =
            filteredWorkflowData &&
            filteredWorkflowData[contextVersion.oid] &&
            filteredWorkflowData[contextVersion.oid].activeSort;
        const hasActiveSort = Object.values(activeSort ? activeSort : {}).some((val) => val.length);
        !hasActiveSort && dispatch(filterAndSortActions.workflowSortByCriteriaRequested('ASC', 'number'));
        dispatch(filterAndSortActions.saveFilterAndSortWorkflowValues(filteredWorkflowData));
    }, [filteredWorkflowData]);

    const filteredChangeRequestList = React.useMemo(() => {
        if (changeRequests && values && Object.values(values).some((val: any) => val && val.length !== 0)) {
            let changeRequestsList = FilterUtils.filterChangeRequests(changeRequests, values, t);

            changeRequestsList =
                filteredWorkflowData &&
                filteredWorkflowData[contextVersion.oid] &&
                filteredWorkflowData[contextVersion.oid].activeSort
                    ? SortUtils.sortChangeRequests(
                          changeRequestsList,
                          filteredWorkflowData[contextVersion.oid].activeSort,
                          contextVersion.outline
                      )
                    : changeRequestsList;

            return changeRequestsList;
        } else if (changeRequests) {
            return filteredWorkflowData &&
                filteredWorkflowData[contextVersion.oid] &&
                filteredWorkflowData[contextVersion.oid].activeSort
                ? SortUtils.sortChangeRequests(
                      changeRequests,
                      filteredWorkflowData[contextVersion.oid].activeSort,
                      contextVersion.outline
                  )
                : changeRequests;
        } else {
            return [];
        }
    }, [filteredWorkflowData, changeRequests]);

    useEffect(() => {
        const setParamsAndTab = (items: IWorkflowChangeRequestV2[], tab: ISidebarToolTab) => {
            if (items.length > 0) {
                setUrlParam(
                    IQueryParam.CHANGE_REQUEST,
                    SortUtils.sortChangeRequests(items, { number: 'ASC' }, contextVersion.outline)[0].processInstanceId
                );
                handleSetActiveToolTab(tab);
            }
        };

        if (activeTool === ISidebarTool.WORKFLOW && activeCrs.length > 0 && !changeRequest) {
            const inWaitlist = activeCrs.filter((cr) => WorkflowUtils.isInWaitListV2(cr));
            const active = activeCrs.filter((cr) => !WorkflowUtils.isInWaitListV2(cr));
            if (itemsKey === ChangeRequestToolTab.ACTIVE && active.length > 0) {
                setParamsAndTab(active, ISidebarToolTab.WORKFLOW__ACTIVE);
            } else if (itemsKey === ChangeRequestToolTab.WAITLIST && inWaitlist.length > 0) {
                setParamsAndTab(inWaitlist, ISidebarToolTab.WORKFLOW__WAITLIST);
            }
        } else if (
            activeTool === ISidebarTool.WORKFLOW &&
            releasedCrs.length > 0 &&
            !changeRequest &&
            itemsKey === ChangeRequestToolTab.RELEASED
        ) {
            setParamsAndTab(releasedCrs, ISidebarToolTab.WORKFLOW__RELEASED);
        }
    }, [activeCrs, releasedCrs, activeTool, changeRequest]);

    return isLoading ? (
        <Spinner />
    ) : (
        <YonderList
            dataTestId={`sidebarCRList${itemsKey}`}
            useVirtualList
            variant="custom"
            componentListItem={ChangeRequestV2ListItem}
            items={filteredChangeRequestList}
            onClick={collapseIfMobile}
            chips={
                contextVersion.oid && {
                    filterAvailable: values && Object.values(values).some((val: any) => val && val.length !== 0),
                    filterType: filterWorkflowTabConfig.type,
                    filterValues: values,
                }
            }
        />
    );
};
