import { GridSize } from '@material-ui/core';

interface ChangeNotificationMapperLayout {
    changeInfoForm: {
        xs?: GridSize;
        md?: GridSize;
        lg?: GridSize;
        xl?: GridSize;
    };
    notificationSettingsTable: {
        xs?: GridSize;
        md?: GridSize;
        lg?: GridSize;
        xl?: GridSize;
    };
}
export const getGridLayout = (): ChangeNotificationMapperLayout => {
    return {
        changeInfoForm: {
            xs: 12,
            md: 12,
            lg: 12,
            xl: 6,
        },
        notificationSettingsTable: {
            xs: 12,
            md: 12,
            lg: 12,
            xl: 6,
        },
    };
};
