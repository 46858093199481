import React from 'react';
import { Workflow } from './Workflow';
import {
    ChangeRequestToolTab,
    FilterTab,
    filterWorkflowTabConfig,
    IApiContextVersion,
    IApplicationStore,
    ISidebarToolTab,
    SortTab,
    sortWorkflowTabConfig,
    useQuery,
} from '@yonder-mind/ui-core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface WorkflowSidebarToolProps {
    contextVersion: IApiContextVersion;
    documentOid: string;
    currentOpenedDocumentOid: string;
    isLoading: boolean;
}

export const WorkflowSidebarTool: React.FC<WorkflowSidebarToolProps> = ({
    contextVersion,
    documentOid,
    currentOpenedDocumentOid,
    isLoading,
}) => {
    const { t } = useTranslation();
    const query = useQuery();
    const { filteredWorkflowData } = useSelector((state: IApplicationStore) => state.filterAndSort);

    const workflowTab = query.get('sidebar-toolTab') ? query.get('sidebar-toolTab') : 'workflow.active';

    const workflowTabMap = {
        [ISidebarToolTab.WORKFLOW__ACTIVE]: ChangeRequestToolTab.ACTIVE,
        [ISidebarToolTab.WORKFLOW__WAITLIST]: ChangeRequestToolTab.WAITLIST,
        [ISidebarToolTab.WORKFLOW__RELEASED]: ChangeRequestToolTab.RELEASED,
    };

    return contextVersion && currentOpenedDocumentOid === contextVersion.oid ? (
        <Workflow
            activeWorkflowTab={workflowTabMap[workflowTab]}
            isDocsLoading={isLoading}
            filterOptions={{
                filterType: filterWorkflowTabConfig.type,
                filterValues: filteredWorkflowData[documentOid] ? filteredWorkflowData[documentOid].activeFilters : {},
                filterTabs: [
                    {
                        dataTestId: '',
                        component: (
                            <FilterTab
                                filterValues={
                                    filteredWorkflowData[documentOid]
                                        ? filteredWorkflowData[documentOid].activeFilters
                                        : {}
                                }
                                filterType={filterWorkflowTabConfig.type}
                                fieldsList={filterWorkflowTabConfig.fields}
                            />
                        ),
                        label: t('filterAndSort.tabs.filter'),
                    },
                    {
                        dataTestId: '',
                        component: (
                            <SortTab
                                sortValues={
                                    filteredWorkflowData[documentOid]
                                        ? filteredWorkflowData[documentOid]?.activeSort
                                        : {}
                                }
                                filterType={filterWorkflowTabConfig.type}
                                fieldsList={sortWorkflowTabConfig.fields}
                            />
                        ),
                        label: t('filterAndSort.tabs.sort'),
                    },
                ],
            }}
        />
    ) : null;
};
