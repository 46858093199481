import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import {
    authSelector,
    changesActions,
    changesSelector,
    ContextVersionUtils,
    docsActions,
    docsSelector,
    filterAndSortActions,
    HighlightPopup,
    HighlightProvider,
    IApiContextVersion,
    IApiContextVersionChange,
    IApiContextVersionMetadata,
    IApiModuleVersionChange,
    IApiTask,
    IApiTaskType,
    IApiUserSettings,
    IApplicationStore,
    IContextContentFlow,
    IDocumentStatus,
    IGroupedChanges,
    IModuleViewToolComponent,
    IReaderTool,
    IReaderToolTab,
    ISidebarTool,
    ISidebarToolComponent,
    ISidebarToolTab,
    ITagDomain,
    Layout,
    linksActions,
    ModuleProvider,
    moduleViewActions,
    OutlineUtils,
    ReaderWrapper,
    routerSelectors,
    SideBarProvider,
    Spinner,
    tasksActions,
    tasksSelector,
    userActions,
} from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../interfaces';
import { crActions, revisionActions, useSelectorUiWeb } from '../../store';
import { ChangeRequest, WorkflowIcon } from '../../components';
import { ConnectedWebsocketProvider, ConnectedWorkflowProvider } from '../../context';
import { WorkflowSidebarTool } from '../../components/SideBar/SideBarTools/Workflow/WorkflowSidebarTool';
import { ChangeRequestView } from '../../components/Workflow/ChangeRequestWorkflow/ChangeRequestView';

interface IDocumentDetailProps
    extends RouteComponentProps<{
        documentOid: string;
    }> {}

interface IActionProps {
    acknowledgeTask: typeof tasksActions.acknowledgeTask;
    tasksRequested: typeof tasksActions.tasksRequested;
    changesRequested: typeof changesActions.changesRequested;
    documentRequested: typeof docsActions.documentRequested;
    documentFlowRequested: typeof docsActions.documentFlowRequested;
    documentFilterRequested: typeof docsActions.documentFilterRequested;
    documentsRequested: typeof docsActions.documentsRequested;
    markTaskAsRead: typeof tasksActions.markTaskAsRead;
    deleteTask: typeof tasksActions.deleteTask;
    userSettingsRequested: typeof userActions.userSettingsRequested;

    moduleLinkTypesRequested: typeof linksActions.moduleLinkTypesRequested;

    resetFilterAndSortWorkflow: typeof filterAndSortActions.resetFilterAndSortWorkflow;
    setCurrentOpenedDocument: typeof filterAndSortActions.setCurrentOpenedDocument;
}

interface IStoreProps {
    contextVersionsMetadata: IApiContextVersionMetadata[];
    document: IApiContextVersion | undefined;
    contentFlowIndex: number;
    documentOid: string;
    isLoading: boolean;
    hasError: boolean;
    readerTools: IReaderTool[];
    readerToolsTab: IReaderToolTab[];
    sidebarTool?: ISidebarTool;
    sidebarToolTab?: ISidebarToolTab;
    readerChangeRequest?: string;
    searchQuery?: string;
    contentFlowCache: IContextContentFlow;
    userSettings: IApiUserSettings;
    activeFilters: string[];
    tagDomains: ITagDomain[] | undefined;

    hasWorkflowRole: boolean;
    canCreateLinks: boolean;

    versions?: IApiContextVersion[];

    documentChanges: { [oid: string]: IApiContextVersionChange[] };
    moduleChanges: { [oid: string]: IApiModuleVersionChange[] };
    contextVersionChanges: IApiContextVersionChange[];
    moduleVersionsChangesOfContextVersion: IApiModuleVersionChange[];
    moduleVersionsChanges: IGroupedChanges<IApiModuleVersionChange>;

    tasks: IApiTask[];
    taskToAcknowledge?: IApiTask;
    taskLoading: boolean;

    currentOpenedDocumentOid: string;
}

interface IProps extends IDocumentDetailProps, IActionProps, IStoreProps {}

const CURRENT_DOCUMENT_CHANGE_TYPES: IApiTaskType[] = [IApiTaskType.MODULE_CHANGE, IApiTaskType.CONTEXT_CHANGE];

export const DocumentDetail: React.FC<IProps & { location: { search: string } }> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const hasAnyWorkflowRole = useSelector(authSelector.hasAnyWorkflowRole);
    const revisionsByContextOid = useSelectorUiWeb((state) => state.workflow.revision.revisionsByContextOid);
    const { isOpen: isChangeRequestViewOpen } = useSelectorUiWeb((state) => state.changeRequestView);

    const documentType = props.document ? props.document.type : 'ovidius-export-type';

    const [mvChangesOfDocRequested, setMvChangesOfDocRequested] = useState(false);
    const [changeRequestCount, setChangeRequestCount] = useState(0);
    const moduleVersionsOids = props.document
        ? OutlineUtils.getModuleVersionOidsFromOutline(props.document.outline)
        : [];

    const contentStyle = useSelector((state: IApplicationStore) =>
        docsSelector.getContentStyle(state, props.document?.type)
    );

    const { contextTypes } = useSelector((state: IApplicationStore) => ({
        ...state.docs,
    }));

    const isRevisionsByContextOidEmpty =
        !Object.keys(revisionsByContextOid).length || !revisionsByContextOid[props.document?.contextOid]?.length;

    useEffect(() => {
        return () => {
            dispatch(moduleViewActions.setOpen({ isOpen: false }));
        };
    }, []);

    useEffect(() => {
        if (props.isLoading) {
            // @ts-ignore
            return;
        }

        if (!props.contextVersionsMetadata || !props.contextVersionsMetadata.length) {
            dispatch(docsActions.contextVersionsMetadataRequested());
        }

        if (!contentStyle) {
            dispatch(docsActions.contentStylesRequested());
        }

        if (props.userSettings.username === '') {
            props.userSettingsRequested();
        }

        if (props.canCreateLinks) {
            props.moduleLinkTypesRequested();
        }
        return () => {
            setMvChangesOfDocRequested(false);
            dispatch(docsActions.removeDocumentError());
        };
    }, [props.location.pathname]);

    useEffect(() => {
        if (isRevisionsByContextOidEmpty && props.document?.contextOid) {
            dispatch(revisionActions.revisionsByContextOidRequested(props.document?.contextOid, props.documentOid));
        }
    }, [props.document?.contextOid]);

    useEffect(() => {
        if (contextTypes.length === 0) {
            dispatch(docsActions.contextTypesRequested());
        }
    }, [contextTypes]);

    useEffect(() => {
        if (props.isLoading || props.hasError) {
            return;
        }
        props.changesRequested({ contextVersionOid: props.documentOid });
        props.tasksRequested({ taskTypes: CURRENT_DOCUMENT_CHANGE_TYPES, contextVersionOid: props.documentOid });
    }, [props.documentOid, props.isLoading, props.hasError]);

    useEffect(() => {
        if (!props.document || !props.document.oid || props.isLoading) {
            return;
        }

        props.setCurrentOpenedDocument(props.document.oid);
        setMvChangesOfDocRequested(false);
    }, [props.isLoading, props.document?.oid]);

    useEffect(() => {
        if (!props.document || !props.document.oid || props.isLoading) {
            return;
        }

        if (hasAnyWorkflowRole) {
            const isDraft = ContextVersionUtils.isDraft(props.document);
            dispatch(
                crActions.getChangeRequestsForWfSidebar({
                    contextOid: props.document.contextOid,
                    contextVersionOid: props.document.oid,
                    isDraft,
                })
            );
        }
    }, [props.isLoading, props.document?.oid, props.document?.contextOid, props.documentOid, hasAnyWorkflowRole]);

    useEffect(() => {
        if (!props.document || !props.document.contextOid || props.isLoading) {
            return;
        }

        if (!mvChangesOfDocRequested && moduleVersionsOids.length !== 0) {
            setMvChangesOfDocRequested(true);
            props.changesRequested({ contextVersionOid: props.documentOid, moduleVersionOids: moduleVersionsOids });
        }
    }, [props.isLoading, props.document?.contextOid, props.documentOid]);

    useEffect(() => {
        if (props.isLoading || props.hasError) {
            return;
        }

        if (props.contentFlowIndex && !props.contentFlowCache[props.contentFlowIndex]) {
            props.documentFlowRequested({
                contentFlowIndex: props.contentFlowIndex,
                documentId: props.documentOid,
                searchTerm: props.searchQuery,
            });
        }
    }, [props.searchQuery, props.isLoading, props.contentFlowCache, props.hasError]);

    useEffect(() => {
        if (props.isLoading || props.hasError) {
            return;
        }

        if (
            !props.document ||
            props.document.oid !== props.documentOid ||
            props.tagDomains === undefined ||
            // TODO fix in backend
            props.document.outline === undefined
        ) {
            props.documentRequested({
                documentId: props.documentOid,
                filters: props.activeFilters,
                searchTerm: props.searchQuery,
            });
        }
    }, [props.documentOid, props.tagDomains, props.isLoading, props.hasError]);

    const moduleVersionChangesRequested = (moduleVersionOid: string) => {
        props.changesRequested({ moduleVersionOids: [moduleVersionOid] });
    };

    const markTaskAsRead = (task: IApiTask) => {
        if (!task.readAt) {
            props.markTaskAsRead({
                task,
                taskTypes: CURRENT_DOCUMENT_CHANGE_TYPES,
                contextVersionOid: props.documentOid,
            });
        }
    };

    const acknowledgeTask = (task: IApiTask) => {
        if (!task.ackAt) {
            props.acknowledgeTask({
                task,
                taskTypes: CURRENT_DOCUMENT_CHANGE_TYPES,
                contextVersionOid: props.documentOid,
            });
        }
    };

    const deleteTask = (task: IApiTask) => {
        if ((task.ackRequired && task.ackAt) || (!task.ackRequired && task.readAt)) {
            props.deleteTask({
                task,
                taskTypes: CURRENT_DOCUMENT_CHANGE_TYPES,
                contextVersionOid: props.documentOid,
            });
        }
    };

    const changeRequestModuleViewTool: IModuleViewToolComponent = {
        key: IReaderToolTab.MODULE_VIEW__CHANGE_REQUESTS,
        name: t('reader.moduleView.tools.changeRequest.title', {
            counter: changeRequestCount ? `(${changeRequestCount})` : '',
        }),
        component: <ChangeRequest setChangeRequestCount={setChangeRequestCount} />,
        dataTestId: 'moduleViewChangeRequest',
    };

    const workflowSidebarTool: ISidebarToolComponent = {
        component: (
            <WorkflowSidebarTool
                isLoading={props.isLoading}
                contextVersion={props.document}
                documentOid={props.documentOid}
                currentOpenedDocumentOid={props.currentOpenedDocumentOid}
            />
        ),
        icon: <WorkflowIcon />,
        id: ISidebarTool.WORKFLOW,
        label: t('reader.drawer.workflow.title'),
        dataTestId: 'sidebarWorkflow',
    };

    return (
        <ModuleProvider>
            <ConnectedWorkflowProvider>
                <ConnectedWebsocketProvider>
                    <SideBarProvider
                        contextVersion={props.document}
                        tool={props.sidebarTool}
                        toolTab={props.sidebarToolTab}
                        extraTools={hasAnyWorkflowRole ? [workflowSidebarTool] : []}
                    >
                        <HighlightProvider contextVersionOid={props?.document?.oid ? props.document.oid : ''}>
                            <Layout
                                title={t('reader.title')}
                                variant="reader"
                                contentCssPaths={[`/api/content-styling/${documentType}`]}
                                navbarProps={{ contextVersion: props.document, contextVersions: props.versions }}
                            >
                                {props.hasError ? (
                                    <Typography className="no-items">{t('reader.noDocument')}</Typography>
                                ) : props.document && props.document.outline && props.tagDomains ? (
                                    <ReaderWrapper
                                        tools={props.readerTools}
                                        toolsTab={props.readerToolsTab}
                                        changeRequest={props.readerChangeRequest}
                                        userSettings={props.userSettings}
                                        documentFlowRequested={props.documentFlowRequested}
                                        contentFlowCache={props.contentFlowCache}
                                        contextVersionChanges={props.contextVersionChanges}
                                        moduleVersionsChangesOfContextVersion={
                                            props.moduleVersionsChangesOfContextVersion
                                        }
                                        moduleVersionsChanges={props.moduleVersionsChanges}
                                        moduleVersionChangesRequested={moduleVersionChangesRequested}
                                        tasks={props.tasks}
                                        markTaskAsRead={markTaskAsRead}
                                        deleteTask={deleteTask}
                                        taskToAcknowledge={props.taskToAcknowledge}
                                        acknowledgeTask={acknowledgeTask}
                                        documentChanges={props.documentChanges}
                                        moduleChanges={props.moduleChanges}
                                        taskLoading={props.taskLoading}
                                        changeRequestModuleViewTool={
                                            hasAnyWorkflowRole ? [changeRequestModuleViewTool] : []
                                        }
                                        changeRequestViewComponent={
                                            isChangeRequestViewOpen
                                                ? [
                                                      <ChangeRequestView
                                                          contextVersionOid={props.document.oid}
                                                          key="cr-view"
                                                      />,
                                                  ]
                                                : []
                                        }
                                    />
                                ) : (
                                    <Spinner className="container" />
                                )}
                            </Layout>
                            <HighlightPopup
                                contextVersionOid={props?.document?.oid ?? ''}
                                documentReleased={props.document?.status === IDocumentStatus.RELEASED}
                            />
                        </HighlightProvider>
                    </SideBarProvider>
                </ConnectedWebsocketProvider>
            </ConnectedWorkflowProvider>
        </ModuleProvider>
    );
};

const actions: IActionProps = {
    tasksRequested: tasksActions.tasksRequested,
    documentFilterRequested: docsActions.documentFilterRequested,
    documentFlowRequested: docsActions.documentFlowRequested,
    documentRequested: docsActions.documentRequested,
    documentsRequested: docsActions.documentsRequested,
    markTaskAsRead: tasksActions.markTaskAsRead,
    deleteTask: tasksActions.deleteTask,
    userSettingsRequested: userActions.userSettingsRequested,
    acknowledgeTask: tasksActions.acknowledgeTask,
    changesRequested: changesActions.changesRequested,
    moduleLinkTypesRequested: linksActions.moduleLinkTypesRequested,
    resetFilterAndSortWorkflow: filterAndSortActions.resetFilterAndSortWorkflow,
    setCurrentOpenedDocument: filterAndSortActions.setCurrentOpenedDocument,
};

const mapStateToProps = (state: IWebApplicationStore, ownProps: IDocumentDetailProps): IStoreProps => {
    const {
        task,
        searchQuery,
        readerTools,
        readerToolsTab,
        sidebarTool,
        sidebarToolTab,
        changeRequest: readerChangeRequest,
    } = routerSelectors.queryParams(state);
    const { documentOid } = ownProps.match.params;
    const document = docsSelector.getDocument(state, documentOid);

    return {
        hasWorkflowRole: authSelector.hasWorkflowRole(state),
        canCreateLinks: authSelector.canCreateLinks(state),
        activeFilters: docsSelector.getActiveFilters(state, documentOid),
        tagDomains: docsSelector.getTagDomains(state, documentOid),
        contentFlowCache: docsSelector.getContentFlowCache(state, documentOid),
        contextVersionsMetadata: state.docs.currentContextVersionsMetadata,
        document,
        contentFlowIndex: docsSelector.getContentFlowIndex(state, documentOid),
        documentOid,
        isLoading: state.docs.isLoading,
        hasError: state.docs.hasDocError,
        searchQuery,
        readerTools,
        readerToolsTab,
        sidebarTool,
        sidebarToolTab,
        readerChangeRequest,
        tasks: tasksSelector.getTasks(state, CURRENT_DOCUMENT_CHANGE_TYPES, documentOid),
        taskToAcknowledge: documentOid && task ? tasksSelector.getTaskOfDocument(state, documentOid, task) : undefined,
        taskLoading: state.tasks.isLoading,
        contextVersionChanges: changesSelector.getContextVersionChanges(state, documentOid),
        moduleVersionsChangesOfContextVersion: changesSelector.getModuleVersionChangesOfContextVersion(
            state,
            documentOid
        ),
        moduleVersionsChanges: changesSelector.getModuleVersionsChanges(state),
        userSettings: state.user.userSettings,
        versions: docsSelector.getVersions(state, documentOid),
        documentChanges: changesSelector.getAllContextVersionChanges(state),
        moduleChanges: changesSelector.getAllModuleVersionChanges(state),
        currentOpenedDocumentOid: state.filterAndSort.currentOpenedDocumentOid,
    };
};

export default connect(mapStateToProps, actions)(DocumentDetail);
