import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    ChangeRequestActivityTab,
    docsActions,
    IQueryParam,
    ITab,
    IWorkflowActivity,
    IWorkflowHistoricActivity,
    PinButton,
    Tabs,
    useHistory,
    userActions,
} from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../../../../interfaces';
import { StatusNotes } from '../../../Tools/StatusNotes';
import { WorkflowCRProposalScreen } from '../../../../../config';
import { Attachment } from '../../../Tools/Attachment';
import { Comments } from '../../../Tools';
import { WorkflowHistory } from '../../../WorkflowHistory';
import { useWorkflow } from '../../../../../context';

const EDITOR_VARS_TO_HIDE = ['TEXT_PROPOSAL', 'MARK_CONTENT', 'MARK_LOCATION'];

const CHANGE_REQUEST_ACTIVITY_TAB_MAP = {
    [ChangeRequestActivityTab.STATUS_NOTES]: 0,
    [ChangeRequestActivityTab.COMMENTS]: 1,
    [ChangeRequestActivityTab.ATTACHMENT]: 2,
    [ChangeRequestActivityTab.HISTORY]: 3,
};

interface ChangeRequestTabsProps {
    changeRequest: IWorkflowActivity | IWorkflowHistoricActivity;
}

export const ActivityView: React.FC<ChangeRequestTabsProps> = ({ changeRequest }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { setUrlParam } = useHistory();

    const [activeTab, setActiveTab] = useState<string>('');

    const defaultChangeRequestActivityTab = useSelector(
        (state: IWebApplicationStore) => state.user.userSettings.uiSettings.defaultChangeRequestActivityTab
    );

    const crModuleVersionOid = changeRequest.variables.CHANGE_REQUEST_MODULE_VERSION_ID;
    const crModuleOid = changeRequest.variables.CHANGE_REQUEST_MODULE_ID;

    const { userSettings } = useSelector((state: IWebApplicationStore) => state.user);

    const { actions, histories: historiesByProcessId } = useWorkflow('process');
    const { processInstanceId } = changeRequest || ({} as any);

    useEffect(() => {
        if (processInstanceId) {
            actions.requestHistory(processInstanceId);
        }
    }, [processInstanceId]);

    const histories = historiesByProcessId[processInstanceId];

    useEffect(() => {
        if (crModuleOid) {
            dispatch(docsActions.moduleVersionsByModuleOidRequested(crModuleOid));
        } else {
            dispatch(docsActions.moduleVersionsByModuleVersionOidRequested(crModuleVersionOid));
        }
    }, [changeRequest?.processInstanceId]);

    useEffect(() => {
        if (
            defaultChangeRequestActivityTab &&
            defaultChangeRequestActivityTab !== ChangeRequestActivityTab.STATUS_NOTES
        ) {
            onChange(CHANGE_REQUEST_ACTIVITY_TAB_MAP[defaultChangeRequestActivityTab]);
        }
    }, [defaultChangeRequestActivityTab]);

    const onChange = (index: number) => {
        const toolTab: ITab = tabs[index];
        setActiveTab(toolTab?.key as ChangeRequestActivityTab);
        setUrlParam(IQueryParam.WORKFLOW_TAB, toolTab?.key.toLowerCase());
    };

    const tabs: ITab[] = [
        {
            key: ChangeRequestActivityTab.STATUS_NOTES,
            name: t('workflow.tools.overview.title'),
            dataTestId: '',
            component: (
                <StatusNotes
                    type="change-request"
                    processInstanceId={changeRequest.processInstanceId}
                    hiddenVars={
                        changeRequest.variables.PROPOSAL_SCREEN === WorkflowCRProposalScreen.EDITOR
                            ? EDITOR_VARS_TO_HIDE
                            : undefined
                    }
                />
            ),
        },
        {
            key: ChangeRequestActivityTab.COMMENTS,
            name: t('workflow.tools.discussion.title'),
            dataTestId: '',
            component: <Comments activity={changeRequest} key={'discussion-' + changeRequest?.id} />,
        },
        {
            key: ChangeRequestActivityTab.ATTACHMENT,
            name: t('workflow.tools.attachment.title'),
            dataTestId: '',
            component: <Attachment changeRequest={changeRequest} />,
        },
        {
            key: ChangeRequestActivityTab.HISTORY,
            name: t('workflow.tools.history.title'),
            dataTestId: '',
            component: <WorkflowHistory histories={histories} />,
        },
    ];

    const updateDefaultChangeRequestViewTab = (key: string) => {
        setActiveTab(key as ChangeRequestActivityTab);
        dispatch(
            userActions.updateUserSettings({
                userSettings: {
                    ...userSettings,
                    uiSettings: {
                        ...userSettings.uiSettings,
                        defaultChangeRequestActivityTab: key.toUpperCase() as ChangeRequestActivityTab,
                    },
                },
            })
        );
    };

    const pinButtonRender = (key: string) => {
        return (
            <PinButton
                isPinned={defaultChangeRequestActivityTab === key}
                onClickPin={() => updateDefaultChangeRequestViewTab(key)}
                dataTestId={`changeRequestViewTabPinButton-${key}`}
            />
        );
    };

    return (
        <Tabs
            key={'tabs-cr'}
            tabs={tabs}
            onChange={onChange}
            value={activeTab}
            secondaryAction={pinButtonRender}
            disableSwipe={true}
        />
    );
};
